<template>
	<div class="rBox">
		<el-form label-width="90px">
			<el-form-item label="上下边距">
				<template>
					<bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
				</template>
			</el-form-item>

			<el-form-item label="按钮名称">
				<template>
					<el-input v-model="curSelData.text" placeholder="请输入按钮名称"></el-input>
				</template>
			</el-form-item>

			<el-form-item label="背景颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
				</template>
			</el-form-item>

			<el-form-item label="文字颜色" class="formsItem">
				<template>
					<bs-color-picker v-model="curSelData.textColor" style="float:left;" />
					<el-button type="primary" class="btnClear" @click="clearColor('textColor')">清除</el-button>
				</template>
			</el-form-item>

			<el-form-item label="文字尺寸">
				<template>
					<bs-slider v-model="curSelData.textSize" :min="0" :max="50" />
				</template>
				
			</el-form-item>
		</el-form>

	</div>
</template>

<script>
	import BsSlider from '../module/BsSlider'
	import BsColorPicker from '../module/BsColorPicker'
	export default {
		components: {
			BsSlider,
			BsColorPicker
		},
		props: {
			curSelData: {
				type: Object,
				required: true
			},
		},
		data() {
			return {

			}
		},
		methods: {
			clearColor(attr) {
				if (attr == 'bgColor') {
					this.curSelData[attr] = 'rgba(8, 185, 239, 1)'
				} else if (attr == 'textColor') {
					this.curSelData[attr] = '#fff'
				}

			},
		}
	}
</script>