<template> 
  <div class="htContainer" :style="[{'background': `url(${item.bg}) center top / 100% 100% no-repeat`}]">
    <div class="topBox">
      <img :src="item.logoImg" class="topLogp" />
      <div>
        <div class="bigTit">{{item.title}}</div>
        <div class="subTit">{{item.subTitle}}</div>
        <div>
          <img :src="item.proImg" class="proImg" />
        </div>
      </div>
    </div>
    <div class="centerBox">
     <div v-for="mItem in item.list" :key="mItem.tit">}
       <div class="mTit" :class="{ 'rightModule': mItem.align == 'right'}">
         <span class="bor" v-if="mItem.align == 'left'"></span>
         <div class="inblock">
           <div class="ztit">
            {{ mItem.tit}}
          </div>
          <div class="eTit">
            {{ mItem.eng}}
          </div>
         </div>
         <span class="bor" v-if="mItem.align == 'right'"></span>
       </div>
       <div class="mBody">
         <template v-if="mItem.type == 1">
           <div v-html="mItem.content" class="contentBox"></div>
         </template>
         <template v-if="mItem.type == 2">
           <div class="sortBox">
             <div v-for="(cItem,index) in mItem.sortList" :key="cItem.con" class="sortBoxItem">
               <div class="num">{{index+1}}</div>
               <div class="con">{{cItem.con}}</div>
             </div>
           </div>
         </template>
         <template v-if="mItem.type == 3">
           <div class="listBox">
             <div v-for="(cItem) in mItem.list" :key="cItem.key" class="listBoxItem">
               <div class="key">{{cItem.key}}</div>
               <div class="val">{{cItem.value}}</div>
             </div>
           </div>
         </template>
         <template v-if="mItem.type == 4">
           <div class="imgListBox">
             <img v-for="(cItem) in mItem.imgList" :key="cItem.url" :src="cItem.url" class="zsImg" />
           </div>
         </template>
         <template v-if="mItem.type == 5">
           <div class="imgBox">
             <img :src="mItem.img" class="oneImg" />
           </div>
         </template>
       </div>
       
     </div>
    </div>
    <div class="bottomBox">
      <div v-for="cItem in item.qyList" :key="cItem.qyTit">
        <div class="mTit">
          <span class="bor"></span>
          <div class="inblock">
            <div class="ztit">
              {{ cItem.qyTit}}
            </div>
            <div class="eTit">
              {{ cItem.qyEng}}
            </div>
          </div>
        </div>
        <div class="splitBor">
          <span class="circle"></span>
        </div>
        <div v-html="cItem.qyText" class="csText"></div>
      </div>
    </div>

    <div class="footerBox">
      <img :src="item.botLogo" />
    </div>

  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    
  },
  methods: {

  }
}
</script>
<style scoped>
.htContainer{
  width: 100%;
  background-size: 100% 100%;
  padding-bottom: 200px;
  position: relative;
}
.topBox{
  position: relative;
  text-align: left;
}
.topLogp{
  width: 206px;
}
.bigTit{
  font-size: 30px;
  font-family: Arial;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding-top: 45px;
}
.subTit{
  font-size: 18px;
  color: #FFFFFF;
  padding-top: 12px;
  text-align: center;
  margin-bottom: 28px;
}
.proImg{
  width: 198px;
  margin: 0 auto;
  display: block;
  padding-bottom: 166px;
}
.mTit{
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center
}
.mTit .bor{
  display: inline-block;
  width: 12px;
  height: 42px;
  background: linear-gradient(0deg, #0973E6, #2C28D4, #0973E6);
  margin-right: 28px;
}
.mTit.rightModule {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
}
.mTit.rightModule .bor{
  margin-right: 0;
  margin-left: 28px;
}
.ztit{
  font-size: 28px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #FFFFFF;
  padding-bottom: 5px;
}
.eTit{
  font-size: 10px;
  color: #fff;
}

.mBody{
  width: 90%;
  border: 1px solid rgba(44, 40, 212, 1);
  margin: 40px auto;
  box-sizing: border-box;
  padding: 25px;
}
.contentBox{
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 18px;
  text-align: left;
}
.sortBoxItem{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}
.sortBox .num{
  font-size: 14px;
  line-height: 18px;
  font-family: "微软黑体";
  font-weight: bold;
  color: #78C6FD;
  width: 20px;
  text-align: left;
}
.sortBox .con {
  font-size: 12px;
  line-height: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  flex: 1;
  text-align: left;
}
.listBox .listBoxItem{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}
.listBoxItem .key {
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #78C6FD;
  width: 100px;
  text-align: left;
  line-height: 18px;
}
.listBoxItem .val{
  font-size: 12px;
  line-height: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  flex: 1;
  text-align: left;
  flex: 1;
}
.imgListBox {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.imgListBox .zsImg {
  width: 144px;
}
.imgListBox .zsImg:nth-child(1){
  margin-right: 10px;
}
.imgBox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.oneImg{
  width: 146px;
}
.bottomBox .mTit{
  padding-top: 50px;
}
.splitBor{
  padding-top: 28px;
  border-bottom: 1px solid #fff;
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.circle{
  width: 6px;
  height: 6px;
  position: absolute;
  right: -7px;
  bottom: -4px;
  border: 1px solid #fff;
  display: block;
  border-radius: 50%;
}
.csText{
  font-size: 14px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #DFE0EA;
  line-height: 20px;
  text-align: left;
  text-indent: 28px;
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
}
.footerBox{
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.footerBox img{
  width: 193px;
}

</style>