<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>
      <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              logo图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.logo"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.logo" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('edgeGateway', 'logo')">选择图片</span></template>
                      </el-input>
                      <!-- <el-input v-model="curSelData.proImg.link" placeholder="请输入链接" style="margin-top: 10px">
                          <template slot="append">选择链接</template>
                      </el-input> -->
                  </el-col>
                  <!-- <el-input v-model="curSelData.proImg.width" placeholder="请输入图片宽度" style="margin-top: 10px">
                      <template slot="append">图片宽度</template>
                  </el-input> -->
              </el-row>
          </el-form>
      </el-card>
      <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              产品图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.proImg.url"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.proImg.url" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('edgeGateway', 'proImg')">选择图片</span></template>
                      </el-input>
                      <el-input v-model="curSelData.proImg.link" placeholder="请输入链接" style="margin-top: 10px">
                          <template slot="append">选择链接</template>
                      </el-input>
                  </el-col>
                  <el-input v-model="curSelData.proImg.width" placeholder="请输入图片宽度" style="margin-top: 10px">
                      <template slot="append">图片宽度</template>
                  </el-input>
              </el-row>
          </el-form>
      </el-card>
      <el-form label-width="90px" label="标题">
         <el-input v-model="curSelData.title" placeholder="请输入标题" style="margin-top: 10px">
            <template slot="append">标题</template>
          </el-input>
      </el-form>
      <el-form-item label="标题颜色">
          <bs-color-picker v-model="curSelData.titleColor" />
      </el-form-item>
      <el-form-item label="文字大小">
        <template>
          <bs-slider v-model="curSelData.titleSize" :min="0" :max="50" />
        </template>
      </el-form-item>
      <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              标题背景
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.titleBg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.titleBg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('edgeGateway', 'titleBg')">选择图片</span></template>
                      </el-input>
                      <!-- <el-input v-model="curSelData.proImg.link" placeholder="请输入链接" style="margin-top: 10px">
                          <template slot="append">选择链接</template>
                      </el-input> -->
                  </el-col>
                  <!-- <el-input v-model="curSelData.proImg.width" placeholder="请输入图片宽度" style="margin-top: 10px">
                      <template slot="append">图片宽度</template>
                  </el-input> -->
              </el-row>
          </el-form>
      </el-card>
      <!--描述-->

      <div class="listWrap">
        <el-card v-for="(item,index) in curSelData.list" :key="item.title" class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delItem(index, 1)">删除</el-button>
          </div>
          <el-row>
            <el-form-item label="标题形式">
               <template>
                  <el-radio v-model="item.isImg" :label="1">图片</el-radio>
                  <el-radio v-model="item.isImg" :label="2">文字</el-radio>
                </template>
            </el-form-item>
          </el-row>
          <el-row v-if="item.isImg == 2">
            <el-col :span="24">
              <el-input v-model="item.title" placeholder="请输入标题" style="margin-top: 10px">
                <template slot="append">标题</template>
              </el-input>
            </el-col>
          </el-row>
          <el-row v-if="item.isImg == 1">
            <el-col :span="24" style="margin-top: 20px">
              <el-col :span="6">
                  <el-image :src="item.titleImg"
                      style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                      <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline" />
                      </div>
                  </el-image>
              </el-col>
              <el-col :span="18">
                  <el-input v-model="item.titleImg" placeholder="请输入图片地址">
                      <template slot="append"><span style="cursor: pointer"
                              @click="selImg('edgeGateway', 'titleImg', index)">选择图片</span></template>
                  </el-input>
                  <el-input v-model="item.titleImgWidth" placeholder="请输入图片宽度" style="margin-top: 10px">
                     <template slot="append">图片宽度</template>
                  </el-input>
              </el-col>
              
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-input v-model="item.content" placeholder="请输入描述" style="margin-top: 10px">
                <template slot="append">描述</template>
              </el-input>
            </el-col>
          </el-row>
        </el-card>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem(1)">添加一项</el-button>        
      </div>
     <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            二维码背景图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.codeBgImg.url"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.codeBgImg.url" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer" @click="selImg('edgeGateway', 'codeBgImg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </el-form>
      </el-card>
      <el-form-item label="背景图片">
        <el-input v-model="curSelData.bgImg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('edgeGateway', 'bgImg')">选择图片</span></template>
        </el-input>
        <el-col :span="18">
        <img :src="curSelData.bgImg" alt="" class="imgClass" :key="curSelData.bgImg">
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.btnClear{
  float: left;
  margin-left: 10px;
}
  .myRow {
    padding: 10px 0;
  }
  .formsItem{
    > label {
      position: relative;
      top:10px;
    }
  }
  .imgClass{
    max-width: 100%;
  }
</style>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
    singleImgae: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    delPic(index) {
      this.curSelData.picList.splice(index, 1)
      this.curSelData.picList.map((item, index) => {
        item.title = '这是标题'+( index + 1)
      })
    },
    selImg(type, attr, index) {
      this.$emit('selImg', type, attr, index)
    },
    addItem() {
      this.curSelData.list.push(
        {
          isImg: 1,  // 1 用图片 2. 用文字
          titleImg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/f354fd38dae9482a071aaecea71b2817.png',
          titleImgWidth: '80%',
          title: '产品描述',
          content: '工业互联网网关，集成4G通信、隔离4～20mA标准接口、485接口，支持RTU等工业通信协议，具有功耗低，稳定可靠的特点；同时该网关集成了1路输出，可实现简单远程控制。',
        }
      )

    },
    delItem(index) {
      this.curSelData.list.splice(index, 1)
    },
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
  }
}
</script>
