import { render, staticRenderFns } from "./WisdomEdit.vue?vue&type=template&id=779a23cd&scoped=true&"
import script from "./WisdomEdit.vue?vue&type=script&lang=js&"
export * from "./WisdomEdit.vue?vue&type=script&lang=js&"
import style0 from "./WisdomEdit.vue?vue&type=style&index=0&id=779a23cd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "779a23cd",
  null
  
)

export default component.exports