<template>
  <div class="rBox">
    <el-form label-width="100px">

      <!-- <el-form-item label="咨询宽度">
        <template>
          <bs-slider v-model="curSelData.lfBtnWidth" :unit="unit" :min="0" :max="100"  />
        </template>
      </el-form-item> -->
      <el-form-item label="咨询背景色" class="formsItem">
        <bs-color-picker v-model="curSelData.lfBtnBgColor" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('lfBtnBgColor')">清除</el-button>
      </el-form-item>
      <el-form-item label="咨询文字颜色" class="formsItem">
        <bs-color-picker v-model="curSelData.lfTextColor" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('lfTextColor')">清除</el-button>
      </el-form-item>

      <!-- <el-form-item label="商城宽度">
        <template>
          <bs-slider v-model="curSelData.rtBtnWidth" :min="0" :max="100" />
        </template>
      </el-form-item> -->
      <el-form-item label="左边文字">
        <el-input v-model="curSelData.lfBtnText" placeholder="请输入左边按钮文字" style="margin-top: 10px">
        </el-input>
      </el-form-item>
      <el-form-item label="右边文字">
        <el-input v-model="curSelData.rtBtnText" placeholder="请输入右边按钮文字" style="margin-top: 10px">
        </el-input>
      </el-form-item>
      <el-form-item label="商城背景色" class="formsItem">
        <bs-color-picker v-model="curSelData.rtBtnBgColor" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('rtBtnBgColor')">清除</el-button>
      </el-form-item>
      <el-form-item label="商城文字颜色" class="formsItem">
        <bs-color-picker v-model="curSelData.rtTextColor" style="float: left" />
        <el-button type="primary" class="btnClear" @click="clearColor('rtTextColor')">清除</el-button>
      </el-form-item>
      <el-form-item label="小程序id">
        <el-input v-model="curSelData.appid" placeholder="请输入微信小程序id" style="margin-top: 10px">
        </el-input>
      </el-form-item>
      <el-form-item label="页面路径">
        <el-input v-model="curSelData.pagePath" placeholder="请输入小程序商城页面路径" style="margin-top: 10px">
        </el-input>
      </el-form-item>
      <el-form-item label="咨询字段">
        <el-input v-model="curSelData.zx_key" placeholder="请输入字段名" style="margin-top: 10px" :disabled="true">
        </el-input>
      </el-form-item>
      <el-form-item label="指定客服">
        <el-row>
          <el-col :span="24">
            <el-input v-model="curSelData.zx_name" placeholder="指定客服">
              <template slot="append"><span style="cursor: pointer" @click="selKf">指定客服</span></template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'

export default {
  components: {
    // BsSlider,
    BsColorPicker,
  },
  props: {
    curSelData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      
    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
      // this.$set(this.curSelData, attr, '#fff')
    },
    selKf() {
      this.$emit('selKf')
    },
  }
}
</script>
