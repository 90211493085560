<template>
  <div class="rBox">
    <el-form label-width="90px">
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>

        </template>
      </el-form-item>
      <!-- <el-form-item label="字体颜色">
        <template>
          <bs-color-picker v-model="curSelData.textColor" />
        </template>
      </el-form-item>
      <el-form-item label="字体大小">
        <template>
          <bs-slider v-model="curSelData.fontSize" :min="9" :max="30" />
        </template>
      </el-form-item>
      <el-form-item label="字体粗细">
        <template>
          <el-radio v-model="curSelData.weight" label="normal">正常</el-radio>
          <el-radio v-model="curSelData.weight" label="bold">加粗</el-radio>
          <el-radio v-model="curSelData.weight" label="lighter">细</el-radio>
        </template>
      </el-form-item> -->
      <el-form-item label="对齐方式">
        <template>
          <el-radio v-model="curSelData.align" label="left">左对齐</el-radio>
          <el-radio v-model="curSelData.align" label="center">居中</el-radio>
          <el-radio v-model="curSelData.align" label="right">右对齐</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="区块链状态">
        <template>
          <el-radio v-model="curSelData.quStatus" label="0">显示</el-radio>
          <el-radio v-model="curSelData.quStatus" label="1">不显示</el-radio>
        </template>
      </el-form-item>
      <!-- <el-form-item label="文本内容">
        <el-input
          v-model="curSelData.content"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
        />
      </el-form-item> -->
      <el-form-item label="二维码背景">
          <el-input v-model="curSelData.scanBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanOne', 'scanBg')">选择图片</span></template>
          </el-input>
      </el-form-item>
      <el-form-item label="背景图片">
          <el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanOne', 'boxBg')">选择图片</span></template>
          </el-input>
        <el-col :span="18">
          <img :src="curSelData.boxBg" alt="" class="imgClass" :key="curSelData.boxBg">
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    clearColor() {
      this.curSelData.bgColor = 'transparent'
    },
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    }
  }
}
</script>
<style scoped>
  .imgClass{
    width: 200px;
    height: auto;
    margin: 15px 0;
  }
</style>
