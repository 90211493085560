<template>
 <div class="my_select_img_wrap">
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    :visible="dialogVisible"
    :before-close="closeDialog"
    title="上传缩略图"
    width="900px"
  >
    <!--copy start-->
    <el-upload
      :action="upUrl"
      :headers="upParams"
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :on-error="handleUploadError"
      name="file"
      :show-file-list="false"
      style="display: inline-block; vertical-align: top;margin-left: 85px;"
    >
      <el-image v-if="!pre" :src="pre">
        <div slot="error" class="image-slot">
          <i class="el-icon-plus" />
        </div>
      </el-image>
      <div v-else class="image">
        <el-image :src="pre" :style="`width:150px;height:150px;`" fit="fill"/>
        <div class="mask">
          <div class="actions">
            <span title="移除" @click.stop="removeImage">
              <i class="el-icon-delete" />
            </span>
          </div>
        </div>
      </div>
    </el-upload>
    <div style="margin-top: 10px;">
      <!-- <el-radio v-model="type" :label="0">用户页面</el-radio>
      <el-radio v-model="type" :label="1">内部页面</el-radio> -->
      <el-form label-width="85px">
        <el-form-item label="页面类型：">
          <el-select v-model="classId" placeholder="请选择">
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form label-width="85px">
        <el-form-item label="备注：">
          <el-input v-model="myRemark" style="width: 30%;" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div>
      <el-button @click="confirmThumb" style="margin-top: 20px;position: relative;z-index: 99">确定</el-button>
    </div>
    <!--copy end-->
  </el-dialog>
 </div>

</template>

<script>
// import { serverUrl } from '@/utils/server.js'
import {noPage} from "../../../api/customPageClass";

let baseUrl = 'https://api.86122m.com'
export default {
  components: {  },
  props: ['ThumbPath','remark'],
  data() {
    let token = JSON.parse(localStorage.getItem('info')).token
    return {
      dialogVisible: false,
      upUrl: this.$store.state.uploadCustomUrl,
      ossUrl: this.$store.state.ossUrl,
      upParams: {'token': token, type: 'pc'},
      pre:null,
      myRemark: '',
      classId:null,
      typeList:[]
      // uploadImgUrl: baseUrl + 'file/uploadObjectOSSs', // 上传的图片服务器地址
      // headers: {
      //   type: 'admin',
      //   token: token,
      // },
    }
  },
  mounted() {
     this.init()
  },
  methods: {
    init(){
      noPage({type:0}).then(response=>{
        if (response.data){
          this.typeList = response.data
        }
      })
    },
    closeDialog(){
      this.dialogVisible=false;
    },
    openDialog() {
      this.dialogVisible=true;
      this.myRemark = this.remark
    },
    handleUploadSuccess(res) {
			console.log('-------------' + JSON.stringify(res))
      // todo $emit
      this.pre = this.ossUrl +res.data[0]
    },
    handleUploadError() {
      // this.pre = 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/crm/VR.png'
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
    removeImage() {
      // todo 删除接口
      this.pre = null;
    },
    confirmThumb() {
      // if(!this.pre) {
      //   this.$message({
      //   type: "warning",
      //     message: "请上传缩略图",
      //   });
      //   return
      // }
      let data = {
        path: this.pre,
        remark: this.myRemark,
        classId:this.classId
      }
      this.$emit('getUploadThumb', data)
    }
  },
  watch: {
    dialogVisible (val) {
      if(val) {
        this.pre= this.ThumbPath
      }else {

      }
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.image {
  position: relative;
  .mask {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
  }
  &:hover .mask {
    opacity: 1;
  }
}
</style>
