<template>
  <div class="rBox">
    <el-form label-width="90px">
      <!-- <el-form-item label="风格">
          <template>
            <el-radio v-model="curSelData.scanStyle" :label="1">风格一</el-radio>
            <el-radio v-model="curSelData.scanStyle" :label="2">风格二</el-radio>
          </template>
      </el-form-item> -->
      <el-form-item label="上下边距">
        <template>
          <bs-slider v-model="curSelData.paddTop" :min="0" :max="100" />
        </template>
      </el-form-item>
      <el-form-item label="左右边距">
        <template>
          <bs-slider v-model="curSelData.paddLeft" :min="0" :max="100" />
        </template>
      </el-form-item>
      <!-- <el-form-item label="二维码背景">
            <el-input v-model="curSelData.scanBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanFive','scanBg')">选择图片</span></template>
          </el-input>
      </el-form-item> -->
      <el-form-item label="背景颜色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.bgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('bgColor')">清除</el-button>
        </template>
      </el-form-item>
      <!-- <el-form-item label="按钮背景色" class="formsItem">
        <template>
          <bs-color-picker v-model="curSelData.btnBgColor" style="float:left;" />
          <el-button type="primary" class="btnClear" @click="clearColor('btnBgColor')">清除</el-button>
        </template>
      </el-form-item> -->

      <el-form-item label="背景图片">
          <el-input v-model="curSelData.boxBg" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('scanFive','boxBg')">选择图片</span></template>
          </el-input>
        <el-col :span="18">
          <img :src="curSelData.boxBg" alt="" class="imgClass">
        </el-col>
      </el-form-item>
     <!-- <el-form-item label="标签展示">
          <template>
            <el-radio v-model="curSelData.showCodeType" :label="1">不显示</el-radio>
            <el-radio v-model="curSelData.showCodeType" :label="2">显示验证码</el-radio>
            <el-radio v-model="curSelData.showCodeType" :label="3">显示标识码</el-radio>
          </template>
      </el-form-item>
      <el-form-item label="标签颜色">
          <template>
              <bs-color-picker v-model="curSelData.CodeTypeColor" style="float:left;" />
              <el-button type="primary" class="btnClear" @click="clearColor('CodeTypeColor')">清除</el-button>
           </template>
      </el-form-item> -->
      <el-form-item label="跳转控件">
          <template>
            <el-radio v-model="curSelData.jumpStyle" :label="1">不显示</el-radio>
            <el-radio v-model="curSelData.jumpStyle" :label="2">风格一</el-radio>
          </template>
      </el-form-item>
      <!--弹窗控件-->
      <!-- <el-form-item label="按钮控件">
          <template>
            <el-radio v-model="curSelData.kjStyle" :label="1">风格一</el-radio>
            <el-radio v-model="curSelData.kjStyle" :label="2">不显示</el-radio>
          </template>
      </el-form-item> -->
      <!-- <template v-if="curSelData.kjStyle === 1">
        <el-card v-for="(item,index) in curSelData.picList" :key="item.url+index" class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
            <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
          </div>
          <el-row>
            <el-col :span="6">
              <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline" />
                </div>
              </el-image>
            </el-col>
            <el-col :span="18">
              <el-input v-model="item.url" placeholder="请输入图片地址">
                <template slot="append"><span style="cursor: pointer" @click="selImg('scanFive','picList', index)">选择图片</span></template>
              </el-input>
              <el-input v-model="item.link" placeholder="请输入链接" style="margin-top: 10px">
                <template slot="append">选择链接</template>
              </el-input>
            </el-col>
            <el-input v-model="item.width" placeholder="请输入图片宽度" style="margin-top: 10px">
              <template slot="append">图片宽度</template>
            </el-input>
          </el-row>
        </el-card>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加</el-button>
      </template> -->

    </el-form>
  </div>
</template>
<script>
import BsSlider from '../module/BsSlider'
import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    BsSlider,
    BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    clearColor(attr) {
      this.curSelData[attr] = 'transparent'
    },
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
    addItem() {
      const width = (100 / (this.curSelData.picList.length + 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.push({ width: width + '%', url: '' })
    },
    delPic(index) {
      const width = (100 / (this.curSelData.picList.length - 1)).toFixed(2)
      this.curSelData.picList.forEach(item => {
        item.width = width + '%'
      })
      this.curSelData.picList.splice(index, 1)
    },
  }
}
</script>
<style scoped>
  .imgClass{
    width: 200px;
    height: auto;
    margin: 15px 0;
  }
</style>
