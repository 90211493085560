<template>
  <div class="rBox">
    <el-form label-width="90px">
        <el-form-item label="标题">
            <el-input v-model="curSelData.title" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <div v-for="item in curSelData.mainPar" :key="item.title">
          <el-form-item label="标题">
              <el-input v-model="item.title" placeholder="请输入标题">
              </el-input>
          </el-form-item>
           <el-form-item label="内容">
              <el-input v-model="item.content" placeholder="请输入内容">
              </el-input>
          </el-form-item>
        </div>
        <div v-for="item in curSelData.subPar" :key="item.title">
          <el-form-item label="标题">
              <el-input v-model="item.title" placeholder="请输入标题">
              </el-input>
          </el-form-item>
           <el-form-item label="内容">
              <el-input v-model="item.content" placeholder="请输入内容">
              </el-input>
           </el-form-item>
        </div>
        <div v-for="(item, index) in curSelData.parDetail" :key="item.title">
          <el-form-item label="模块名称">
            <el-input v-model="item.title" placeholder="请输入模块名称">
            </el-input>
          </el-form-item>
          <el-form-item label="是否折叠">
            <template>
                <el-radio v-model="item.type" :label="1">是</el-radio>
                <el-radio v-model="item.type" :label="2">否</el-radio>
            </template>
          </el-form-item>
          <template v-if="item.type === 1">
             <el-card v-for="(innerItem, innerIndex) in item.list" :key="innerItem.title" class="box-card">
               <div slot="header" class="clearfix" @click="delItem2(index, innerIndex)">删除</div>
                <el-form-item label="第一列">
                  <el-input v-model="innerItem.c1" />
                </el-form-item>
                <el-form-item label="第二列">
                  <el-input v-model="innerItem.c2" />
                </el-form-item>
                <el-form-item label="第三列">
                  <el-input v-model="innerItem.c3" />
                </el-form-item>
             </el-card>
             <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem2(index)">添加一行</el-button>
          </template>
          <template v-else-if="item.type === 2">
           <el-form-item label="模块内容">
              <el-input v-model="item.value" placeholder="请输入模块内容">
              </el-input>
           </el-form-item>
          </template>
        </div>
        <el-button style="margin:20px auto;width: 80%;display: block" @click="addItem">添加一项</el-button>

        <el-card class="box-card" style="margin-top: 10px">
          <div slot="header" class="clearfix">
              报告图片
          </div>
          <el-form label-width="90px">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.reportImg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.reportImg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('deviceNameplate', 'reportImg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </el-form>
      </el-card>
    </el-form>
  </div>
</template>
<script>
// import BsSlider from '../module/BsSlider'
// import BsColorPicker from '../module/BsColorPicker'
export default {
  components: {
    // BsSlider,
    // BsColorPicker
  },
  props: {
    curSelData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {}
    }
  },
  data() {
    return {

    }
  },
  methods: {
    selImg(type, attr) {
      this.$emit('selImg', type, attr)
    },
    addItem() {
      this.curSelData.parDetail.push({
        title: '模块名称',
        type: 1,
        list:[
          {
            c1: '项目名称',
            c2: '实测值',
            c3: '要求值'
          },
        ],
        value: '模块内容',
      })
    },
    addItem2(index) {
      this.curSelData.parDetail[index].list.push(
        {
          c1: '项目名称',
          c2: '实测值',
          c3: '要求值'
        }
      )
    },
    delItem2(index,innerIndex) {
      this.curSelData.parDetail[index].list.splice(innerIndex, 1)
    },
    delItem(type,index) {
      if(type === 1) {
        this.curSelData.gnList.splice(index, 1)
      }else  if(type === 2) {
        this.curSelData.parList.splice(index, 1)
      }
    },
  }
}
</script>
<style scoped>

</style>
