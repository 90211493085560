<template>
	<div class="app-container">
		<div style="height: 100%; display: flex; flex-direction: row; justify-content: center;">
			<!-- <div style="width:25%;float:left; min-height: 100px;">
        <el-card class="box-card" style="width: 90%" v-if="pageMode === 'addTemplate' || pageMode === 'editTemplate'">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="通用" name="1">
              <div class="buttonsWrap">
                <el-button size="mini" @click="addSwipe">轮播图</el-button>
                <el-button size="mini" @click="addImg">单图组</el-button>
                <el-button size="mini" @click="addAssist">辅助空白</el-button>
                <el-button size="mini" @click="addImgTextList">图文列表1</el-button>
                <el-button size="mini" @click="addImgText2">图文列表2</el-button>
                <el-button size="mini" @click="addTit1">标题1行</el-button>
                <el-button size="mini" @click="addCon1">内容格</el-button>
                <el-button size="mini" @click="addDetail1">详情介绍</el-button>
                <el-button size="mini" @click="addMulImg">多图</el-button>
                <el-button size="mini" @click="addSearchInput">搜索框</el-button>
                <el-button size="mini" @click="addProductGroup">商品组</el-button>
                <el-button size="mini" @click="addVideo">视频</el-button>
              </div>
            </el-collapse-item>
            <el-collapse-item title="扫码" name="2">
              <div class="buttonsWrap">
                <el-button size="mini" @click="scanOne">扫码组件一</el-button>
                <el-button size="mini" @click="scanTwo">扫码组件二</el-button>
                <el-button size="mini" @click="scanThree">扫码组件三</el-button>
                <el-button size="mini" @click="scanFour">扫码组件四</el-button>
                <el-button size="mini" @click="scanFive">扫码组件五</el-button>
              </div>
            </el-collapse-item>
            <el-collapse-item title="底部菜单" name="3">
              <div class="buttonsWrap">
                <el-button size="mini" @click="bottonmMenu1">商城+咨询</el-button>
                <el-button size="mini" @click="expertTalk">咨询按钮</el-button>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </div> -->
			<!-- 页面展示-->
			<div style="width: 30%;">
				<div style="width: 375px;background-size: 100% 100%;" class="diy_phone">
					<div class="head_phone" @click="globalSettimg()" />
					<div class="headBox" @click="globalSettimg()">
						<div v-if="pagesData.pageName" class="titHead">{{ pagesData.pageName }}</div>
						<div v-else class="titHead">页面名称</div>
						<div class="backBox">
							<img src="../../assets/images/backImg.png" alt="" class="iconImg">
							返回
						</div>
					</div>
					<div class="content-phone" id="talkBox" ref="rightContent">
						<draggable v-model="itemList" class="wrapper"
							:style="hasPages ? `background-image: url(${pagesData.picList[0].url});background-size: 100% 100%; background-color: ${pagesData.bgColor};text-align: center;padding: ${pagesData.paddTop}px ${pagesData.paddLeft}px` : ''">
							<div v-for="(item,index) in itemList" :key="item.type+index">
								<div v-if="item.type==='swipe'" @click="changeIndex(index, $event)" :class="'pag_'+index">
									<my-swipe :item="item" class="showItem" style="position: relative"
										:class="[index === curSelIndex?'item-sel':'']" />
								</div>
								<div v-if="item.type==='mulImage'||item.type==='image'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" style="overflow: hidden"
									@click="changeIndex(index, $event)">
									<my-image :item="item" />
								</div>
								<div v-if="item.type==='text'" class="showItem" :class="[index === curSelIndex?'item-sel':'pag_'+index]"
									@click="changeIndex(index, $event)">
									<my-text :item="item" />
								</div>
								<div v-if="item.type==='assist'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<AssistKb :item="item" />
								</div>
								<div v-if="item.type==='qrcode'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<qr-code-edit :data="item" />
								</div>
								<div v-if="item.type==='scanOne'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<scanOne :data="item" />
								</div>
								<div v-if="item.type==='scanTwo'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanTwo :data="item"></ScanTwo>
								</div>
								<div v-if="item.type==='scanThree'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanThree :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanThree>
								</div>
								<div v-if="item.type==='scanFour'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanFour :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanFour>
								</div>
								<div v-if="item.type==='scanFive'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanFive :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanFive>
								</div>
								<!--边缘计算网关--->
								<div v-if="item.type === 'edgeGateway'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<EdgeGateway :item="item"></EdgeGateway>
								</div>
								<!--核辐射设备-->
								<div v-if="item.type === 'equipment'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<Equipment :item="item"></Equipment>
								</div>
								<!--设备名牌-->
								<div v-if="item.type === 'deviceNameplate'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<DeviceNameplate :item="item"></DeviceNameplate>
								</div>

								<!--洪洞模版-->
								<div v-if="item.type === 'facility'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<Facility :item="item"></Facility>
								</div>

								<div v-if="item.type==='scanSix'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanSix :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanSix>
								</div>
								<div v-if="item.type==='scanSeven'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanSeven :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanSeven>
								</div>

								<div v-if="item.type==='scanNine'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanNine :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanNine>
								</div>
								<div v-if="item.type==='scanTen'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ScanTen :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanTen>
								</div>

								<div v-if="item.type==='myQrCode'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<MyQrCode :data="item" @veridicalCode="veridicalCode" :verState="verState"></MyQrCode>
								</div>
								<div v-if="item.type==='bottonmMenu1'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<bottonmMenu1 :item="item"></bottonmMenu1>
								</div>
								<div v-if="item.type==='bottonmMenu2'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<bottonmMenu2 :item="item"></bottonmMenu2>
								</div>
								<div v-if="item.type=== 'searchInput'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<my-search-input :item="item" />
								</div>
								<div v-if="item.type=== 'imgTextList'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<my-imgText-list :item="item" />
								</div>
								<div v-if="item.type=== 'imgText3'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<MyImgText3 :item="item" />
								</div>
								<div v-if="item.type=== 'imgText2'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<MyImgText2 :item="item"></MyImgText2>
								</div>
								<div v-if="item.type=== 'titleImg'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<TitleImg :item="item"></TitleImg>
								</div>
								<div v-if="item.type=== 'qkPage'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<QKPage :item="item"></QKPage>
								</div>

								<div v-if="item.type=== 'userInfo'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<SetUserInfo :item="item"></SetUserInfo>
								</div>

								<div v-if="item.type=== 'setHandle'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<SetHandle :item="item"></SetHandle>
								</div>

								<div v-if="item.type=== 'setParameter'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<SetParameter :item="item"></SetParameter>
								</div>

								<div v-if="item.type=== 'detailBlock1'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<DetailBlock1 :item="item"></DetailBlock1>
								</div>
								<div v-if="item.type=== 'tit1'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<Title1 :item="item"></Title1>
								</div>
								<div v-if="item.type ==='moreStyleTitle'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<MoreStyleTitle :item="item"></MoreStyleTitle>
								</div>
								<div v-if="item.type=== 'con1'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ConStyle1 :item="item"></ConStyle1>
								</div>
								<div v-if="item.type === 'productGroup'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<productGroup :item="item" />
								</div>
								<div v-if="item.type === 'oneVideo'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<OneVideo :item="item"></OneVideo>
								</div>
								<div v-if="item.type === 'expertTalk'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ExpertTalk :item="item"></ExpertTalk>
								</div>
								<div v-if="item.type === 'aloneTalk'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<AloneTalk :item="item"></AloneTalk>
								</div>
								<div v-if="item.type ==='chatFix'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ChatFix :item="item"></ChatFix>
								</div>
								<div v-if="item.type ==='mulVideo'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<MulVideo :item="item"></MulVideo>
								</div>
								<div v-if="item.type ==='pdfShow'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<pdfShow :item="item"></pdfShow>
								</div>
								<div v-if="item.type ==='report'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<MyReport :item="item"></MyReport>
								</div>
								<div v-if="item.type==='proInfo1'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ProInfo1Show :item="item"></ProInfo1Show>
								</div>
								<div v-if="item.type==='proInfo1'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ProInfo1Show :item="item"></ProInfo1Show>
								</div>
								<div v-if="item.type==='productCode'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<ProductCode :data="item"></ProductCode>
								</div>
								<div v-if="item.type==='packingCode'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<PackingCode :data="item"></PackingCode>
								</div>

								<div v-if="item.type==='boxCode'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<BoxCode :data="item"></BoxCode>
								</div>

								<div v-if="item.type==='boxCode2'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<BoxCode2 :data="item"></BoxCode2>
								</div>

								<div v-if="item.type ==='xhCertificate'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<XhCertificate :item="item"></XhCertificate>
								</div>

								<div v-if="item.type ==='xhCertificate2'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<XhCertificate2 :item="item"></XhCertificate2>
								</div>

								<!--udi码展示-->
								<div v-if="item.type === 'datamatrix'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<MyDatamatrix :item="item" @veridicalCode="veridicalCode" :verState="verState"></MyDatamatrix>
								</div>

								<!--酒瓶NFC-->
								<div v-if="item.type === 'bottleState'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<BottleState :item="item"></BottleState>
								</div>

								<div v-if="item.type === 'scanBJWL'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<ScanBJWL :data="item" @veridicalCode="veridicalCode" :verState="verState"></ScanBJWL>
								</div>

								<div v-if="item.type === 'bottonmMenu3'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<bottonmMenu3 :item="item"></bottonmMenu3>
								</div>

								<div v-if="item.type === 'extensionPage'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<ExtensionPage :item="item"></ExtensionPage>
								</div>

								<div v-if="item.type === 'realSta'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<RealSta :item="item"></RealSta>
								</div>

								<div v-if="item.type === 'zxCountDown'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<ZxCountDown :item="item"></ZxCountDown>
								</div>

								<div v-if="item.type === 'swiperVideo'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<SwiperVideo :item="item"></SwiperVideo>
								</div>

								<div v-if="item.type === 'zxkf'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<ZXKF :item="item"></ZXKF>
								</div>

								<div v-if="item.type === 'telBtn'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<Telbtn :item="item"></Telbtn>
								</div>

								<div v-if="item.type === 'shoppingBtn'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<ShoppingBtn :item="item"></ShoppingBtn>
								</div>

								<div v-if="item.type === 'productZone'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<productZone :item="item" />
								</div>

								<div v-if="item.type === 'read'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<read :item="item" />
								</div>

								<div v-if="item.type === 'wisdom'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<wisdom :item="item" />
								</div>

								<div v-if="item.type === 'scanInfo'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<scanInfo :data="item" @veridicalCode="veridicalCode" :verState="verState">
									</scanInfo>
								</div>

								<div v-if="item.type === 'changeZone'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<changeZone :data="item" @veridicalCode="veridicalCode" :verState="verState">
									</changeZone>
								</div>

								<!--张裕白兰地-->
								<div v-if="item.type === 'BLDWine'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<BLDWine :item="item"></BLDWine>
								</div>

								<!--方圆证书-->
								<div v-if="item.type === 'Quality'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<Quality :item="item"></Quality>
								</div>

								<!--外部链接-->
								<div v-if="item.type === 'outLink'" class="showItem"
									:class="[index === curSelIndex ? 'item-sel' : 'pag_' + index]" @click="changeIndex(index, $event)">
									<OutLink :item="item"></OutLink>
								</div>

								<div v-if="item.type==='pages'" class="showItem"
									:class="[index === curSelIndex?'item-sel':'pag_'+index]" @click="changeIndex(index, $event)">
									<PagesSet :data="pagesData" />
								</div>

							</div>
						</draggable>
					</div>
					<div style="background-color: black">
						<div class="phone-foot" />
					</div>
				</div>
			</div>
			<!-- 页面编辑-->
			<div class="editNowBox">
				<el-button type="danger" class="delComBtn" @click="delItem">删除</el-button>
				<!--        <div class="myIntegralSeting" style="width: 70%">-->
				<!--          <el-form>-->
				<!--            <el-form-item label="是否设置积分" prop="isIntegral">-->
				<!--              <el-radio-group v-model="isIntegral">-->
				<!--                <el-radio :label="true">是</el-radio>-->
				<!--                <el-radio :label="false">否</el-radio>-->
				<!--              </el-radio-group>-->
				<!--            </el-form-item>-->
				<!--            <el-form-item label="分值" prop="integral" v-if="isIntegral" type="number">-->
				<!--              <el-input-number v-model="integral" @change="handleChange" :min="0" :max="10" label="分值"></el-input-number><span class="smallTip">(范围0~10)</span>-->
				<!--            </el-form-item>-->
				<!--          </el-form>-->
				<!--        </div>-->
				<div v-if="curSelData.type==='swipe'" class="swipe_edit" style="width: 70%">
					<swpie-edit :cur-sel-data="curSelData" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='image'" class="img_edit" style="width: 70%">
					<image-item-edit :cur-sel-data="curSelData" :single-imgae="true" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='mulImage'" class="mulimg_edit" style="width: 70%">
					<image-item-edit :cur-sel-data="curSelData" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='text'" class="text-edit">
					<text-edit :cur-sel-data="curSelData" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='qrcode'" class="qrcode-edit">
					<el-form label-width="90px">
						<el-form-item label="背景颜色">
							<template>
								<bs-color-picker v-model="curSelData.bgColor" />
							</template>
						</el-form-item>

						<el-form-item label="背景图片">
							<template>
								<bs-image-sel v-model="curSelData.bgImg" />
							</template>
						</el-form-item>

						<el-form-item label="上边距">
							<template>
								<bs-slider v-model="curSelData.top" />
							</template>
						</el-form-item>
						<el-form-item label="下边距">
							<template>
								<bs-slider v-model="curSelData.bottom" />
							</template>
						</el-form-item>
						<el-form-item label="左边距">
							<template>
								<bs-slider v-model="curSelData.left" />
							</template>
						</el-form-item>
						<el-form-item label="右边距">
							<template>
								<bs-slider v-model="curSelData.right" />
							</template>
						</el-form-item>
						<el-form-item label="大小">
							<el-input v-model.number="curSelData.size" type="number" />
						</el-form-item>
						<el-form-item label="内容">
							<el-input v-model="curSelData.text" />
						</el-form-item>
					</el-form>
				</div>
				<div v-if="curSelData.type==='scanOne'" class="scanOne-edit">
					<ScanForOne :cur-sel-data="curSelData" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='scanTwo'" class="scanTwo-edit">
					<ScanForTwo :cur-sel-data="curSelData" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='scanThree'" class="scanThree-edit">
					<ScanForThree :cur-sel-data="curSelData" @selImg="selImg" />
				</div>

				<div v-if="curSelData.type==='scanFour'" class="scanFour-edit">
					<ScanForFour :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='scanFive'" class="scanFive-edit">
					<ScanForFive :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='scanSix'" class="scanSix-edit">
					<ScanForSix :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='scanSeven'" class="scanSeven-edit">
					<ScanForSeven :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='scanNine'" class="scanNine-edit">
					<ScanForNine :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='scanTen'" class="scanTen-edit">
					<ScanForTen :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='myQrCode'" class="scanSix-edit">
					<MyQrCodeEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='bottonmMenu1'" class="bottonmMenu1-edit">
					<BottonmMenu1Edit :cur-sel-data="curSelData" @selKf="selKf" />
				</div>
				<div v-if="curSelData.type==='bottonmMenu2'" class="bottonmMenu2-edit">
					<BottonmMenu2Edit :cur-sel-data="curSelData" @selKf="selKf" />
				</div>

				<div v-if="curSelData.type==='assist'" class="scanOne-edit">
					<AssistEdit :cur-sel-data="curSelData" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='searchInput'" class="searchInput-edit">
					<SearchInputEdit :cur-sel-data="curSelData" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='imgTextList'" class="imgTextList-edit">
					<MyImgTextListEdit :cur-sel-data="curSelData" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='imgText2'" class="imgText2-edit">
					<MyImgText2Edit :cur-sel-data="curSelData" @selImg="selImg" />
					<!-- <el-button type="danger" class="delComBtn" @click="delItem">删除</el-button> -->
				</div>
				<div v-if="curSelData.type==='imgText3'" class="imgText3-edit">
					<MyImgText3Edit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='titleImg'" class="imgText2-edit">
					<TitleImgEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type === 'qkPage'" class="imgText2-edit">
					<QKPageEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type === 'userInfo'" class="imgText2-edit">
					<SetUserInfoEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type === 'setHandle'" class="imgText2-edit">
					<SetHandleEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type === 'setParameter'" class="imgText2-edit">
					<SetParameterEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='detailBlock1'" class="detailBlock1-edit">
					<DetailBlock1Edit :cur-sel-data="curSelData" />
				</div>
				<div v-if="curSelData.type==='tit1'" class="tit1-edit">
					<Title1Edit :cur-sel-data="curSelData" />
				</div>
				<div v-if="curSelData.type==='moreStyleTitle'" class="moreStyleTitle-edit">
					<MoreStyleTitleEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type==='con1'" class="con1-edit">
					<ConStyle1Edit :cur-sel-data="curSelData" />
				</div>
				<div v-if="curSelData.type==='productGroup'" class="productGroup-edit">
					<productGroupEdit :cur-sel-data="curSelData" @selProduct="selProduct" @selImg="selImg" />

				</div>

				<div v-if="curSelData.type==='xhCertificate'" class="XhCertificate-edit">
					<XhCertificateEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>

				<div v-if="curSelData.type==='xhCertificate2'" class="XhCertificate-edit">
					<XhCertificateEdit2 :cur-sel-data="curSelData" @selImg="selImg" />
				</div>

				<div v-if="curSelData.type==='oneVideo'" class="oneVideo-edit">
					<OneVideoEdit :cur-sel-data="curSelData" @selVideo="selVideo" @selImg="selImg" />
				</div>

				<div v-if="curSelData.type==='expertTalk'" class="expertTalk-edit">
					<ExpertTalkEdit :cur-sel-data="curSelData" @selImg="selImg" @selKf="selKf" />
				</div>
				<div v-if="curSelData.type==='aloneTalk'" class="aloneTalk-edit">
					<AloneTalkEdit :cur-sel-data="curSelData" @selKf="selKf" />
				</div>

				<div v-if="curSelData.type==='chatFix'" class="chatFix-edit">
					<ChatFixEdit :cur-sel-data="curSelData" @selImg="selImg" @selKf="selKf" />
				</div>

				<div v-if="curSelData.type==='mulVideo'" class="mulVideo-edit">
					<MulVideoEdit :cur-sel-data="curSelData" @selVideo="selVideo" />
				</div>

				<div v-if="curSelData.type==='pdfShow'" class="pdfShow-edit">
					<pdfShowEdit :cur-sel-data="curSelData" @selVideo="selVideo" />
				</div>

				<div v-if="curSelData.type==='report'" class="report-edit">
					<MyReportEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>

				<div v-if="curSelData.type==='proInfo1'" class="swipe_edit" style="width: 70%">
					<ProInfo1Edit :cur-sel-data="curSelData" @selImg="selImg"></ProInfo1Edit>
				</div>

				<div v-if="curSelData.type==='productCode'" class="swipe_edit" style="width: 70%">
					<ProductCodeEdit :cur-sel-data="curSelData" @selImg="selImg"></ProductCodeEdit>
				</div>
				<div v-if="curSelData.type==='packingCode'" class="swipe_edit" style="width: 70%">
					<PackingCodeEdit :cur-sel-data="curSelData" @selImg="selImg"></PackingCodeEdit>
				</div>

				<div v-if="curSelData.type==='boxCode'" class="swipe_edit" style="width: 70%">
					<BoxCodeEdit :cur-sel-data="curSelData" @selImg="selImg"></BoxCodeEdit>
				</div>

				<div v-if="curSelData.type==='boxCode2'" class="swipe_edit" style="width: 70%">
					<BoxCodeEdit2 :cur-sel-data="curSelData" @selImg="selImg"></BoxCodeEdit2>
				</div>

				<!--udi码编辑-->
				<div v-if="curSelData.type === 'datamatrix'" class="datamatrix-edit">
					<MyDatamatrixEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>

				<!--酒瓶NFC-->
				<div v-if="curSelData.type === 'bottleState'" class="bottleState-edit">
					<BottleStateEdit :cur-sel-data="curSelData" @selImg="selImg"></BottleStateEdit>
				</div>

				<!--边缘计算网关-->
				<div v-if="curSelData.type === 'edgeGateway'" class="edgeGateway-edit">
					<EdgeGatewayEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>

				<!--核辐射设备-->
				<div v-if="curSelData.type === 'equipment'" class="equipment-edit">
					<EquipmentEdit :cur-sel-data="curSelData" @selImg="selImg"></EquipmentEdit>
				</div>

				<!--设备名牌-->
				<div v-if="curSelData.type === 'deviceNameplate'" class="deviceNameplate-edit">
					<DeviceNameplateEdit :cur-sel-data="curSelData" @selImg="selImg"></DeviceNameplateEdit>
				</div>

				<!--洪洞模版-->
				<div v-if="curSelData.type === 'facility'" class="facility-edit">
					<FacilityEdit :cur-sel-data="curSelData" @selImg="selImg"></FacilityEdit>
				</div>

				<div v-if="curSelData.type === 'scanBJWL'" class="scanBJWL-edit">
					<ScanForBJWL :cur-sel-data="curSelData" @selImg="selImg" />
				</div>

				<div v-if="curSelData.type === 'bottonmMenu3'" class="bottonmMenu3-edit">
					<BottonmMenu3Edit :cur-sel-data="curSelData" @selKf="selKf" />
				</div>

				<!--推广扫码页-->
				<div v-if="curSelData.type === 'extensionPage'" class="extensionPage-edit">
					<ExtensionPageEdit :cur-sel-data="curSelData" @selImg="selImg"></ExtensionPageEdit>
				</div>

				<!--实时统计-->
				<div v-if="curSelData.type === 'realSta'" class="extensionPage-edit">
					<RealStaEdit :cur-sel-data="curSelData" @selImg="selImg"></RealStaEdit>
				</div>
				<!--涿溪倒计时-->
				<div v-if="curSelData.type === 'zxCountDown'" class="extensionPage-edit">
					<ZxCountDownEdit :cur-sel-data="curSelData" @selImg="selImg"></ZxCountDownEdit>
				</div>
				<!--滚动视频-->
				<div v-if="curSelData.type === 'swiperVideo'" class="extensionPage-edit">
					<SwiperVideoEdit :cur-sel-data="curSelData" @selImg="selImg" @selVideo="selVideo"></SwiperVideoEdit>
				</div>

				<!--涿溪客服-->
				<div v-if="curSelData.type === 'zxkf'" class="extensionPage-edit">
					<ZXKFEdit :cur-sel-data="curSelData" @selImg="selImg"></ZXKFEdit>
				</div>
				<!--扫码信息-->
				<div v-if="curSelData.type === 'scanInfo'" class="scanInfo-edit">
					<ScanInfoEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<!--兑换专区-->
				<div v-if="curSelData.type === 'changeZone'" class="changeZone-edit">
					<changeZoneEdit :cur-sel-data="curSelData" @selImg="selImg" />
				</div>
				<!--产品专区-->
				<div v-if="curSelData.type === 'productZone'" class="productZone-edit">
					<productZoneEdit :cur-sel-data="curSelData" @selProduct="selProduct" @selImg="selImg" />
				</div>
				<!--推荐阅读-->
				<div v-if="curSelData.type === 'read'" class="changeZone-edit">
					<readEdit :cur-sel-data="curSelData" @selProduct="selProduct" @selImg="selImg" />
				</div>
				<div v-if="curSelData.type === 'telBtn'" class="telBtn-edit">
					<TelbtnEdit :cur-sel-data="curSelData" @selImg="selImg"></TelbtnEdit>
				</div>

				<div v-if="curSelData.type === 'shoppingBtn'" class="extensionPage-edit">
					<ShoppingBtnEdit :cur-sel-data="curSelData" @selImg="selImg"></ShoppingBtnEdit>
				</div>

				<!--智慧沙盘-->
				<div v-if="curSelData.type === 'wisdom'" class="changeZone-edit">
					<wisdomEdit :cur-sel-data="curSelData" @selProduct="selProduct" @selImg="selImg" />
				</div>

				<!--张裕白兰地-->
				<div v-if="curSelData.type === 'BLDWine'" class="deviceNameplate-edit">
					<BLDWineEdit :cur-sel-data="curSelData" @selImg="selImg"></BLDWineEdit>
				</div>

				<!--方圆证书-->
				<div v-if="curSelData.type === 'Quality'" class="deviceNameplate-edit">
					<QualityEdit :cur-sel-data="curSelData" @selImg="selImg"></QualityEdit>
				</div>
				<!--外部链接-->
				<div v-if="curSelData.type === 'outLink'" class="extensionPage-edit">
					<OutLinkEdit :cur-sel-data="curSelData"></OutLinkEdit>
				</div>

				<div v-if="curSelData.type==='pages'" class="pages-edit">
					<PageSet :cur-sel-data="pagesData" @selImg="selImg" />
				</div>

			</div>
		</div>
		<div class="saveBox">

			<template v-if="pageMode === 'addTemplate'">
				<div class="saveTitle" @click="savePage">保存模版</div>
			</template>

			<template v-else-if="pageMode === 'editTemplate'">
				<div class="saveTitle" @click="savePage">更新模版</div>
			</template>

			<template v-else-if="pageMode === 'addPage'">
				<div class="saveTitle" @click="savePageBefore">保存页面</div>
			</template>

			<template v-else-if="pageMode === 'editPage'">
				<div class="saveTitle" @click="savePageBefore('page')">更新页面</div>
				<!-- <div class="saveTitle" @click="savePage('template')">另存为模版</div> -->
			</template>

		</div>

		<SelImage ref="selImage" type="" />
		<SelIcon ref="SelIcon" />
		<SelProduct ref="selProduct" />
		<SelVideo ref="selVideo" @getVideo="getVideo" />
		<SelKf ref="selKf"></SelKf>
		<!--上传缩略图-->
		<UploadThumbnail ref="UploadThumbnail" :ThumbPath="ThumbPath" :remark="remark" @getUploadThumb="getUploadThumb">
		</UploadThumbnail>
	</div>
</template>

<script>
	import {
		fetchSaveTemplate,
		fetchGetTemplate,
		fetchUpdateTemplate,
		fetchSavePage,
		fetchPageData,
		fetchUpdatePage
	} from '@/api/customPage'
	import BsSlider from './module/BsSlider'
	import BsColorPicker from './module/BsColorPicker'
	import SelImage from './module/SelImage'
	import 'vant/lib/index.css'
	import QrCodeEdit from './module/QrCodeEdit'
	import BsImageSel from './module/BsImageSel'
	import draggable from 'vuedraggable'
	import SelIcon from './module/IconShow'
	import SelProduct from './module/SelProduct'
	import SelVideo from './module/SelVideo'
	import UploadThumbnail from './module/UploadThumbnail' // 保存模版上传缩略图


	// 页面展示
	import ScanOne from './module/ScanOne'
	import ScanTwo from './module/ScanTwo'
	import ScanThree from './module/ScanThree'
	import ScanFour from './module/ScanFour'
	import ScanFive from './module/ScanFive'
	import ScanSix from './module/ScanSix'
	import ScanSeven from './module/ScanSeven'
	import ScanNine from './module/ScanNine'
	import ScanTen from './module/ScanTen'
	import PagesSet from './module/PageSet'
	import MySwipe from './module/MySwipe'
	import MyImage from './module/MyImage'
	import MyText from './module/MyText'
	import AssistKb from './module/AssistKb'
	import MySearchInput from './module/MySearchInput'
	import MyImgTextList from './module/MyImgTextList'
	import MyImgText2 from './module/MyImgText2'
	import MyImgText3 from './module/MyImgText3'
	import TitleImg from './module/TitleImg'
	import DetailBlock1 from './module/DetailBlock1'
	import Title1 from './module/Title1'
	import ConStyle1 from './module/ConStyle1'
	import productGroup from './module/ProductGroup'
	import BottonmMenu1 from './module/BottonmMenu1'
	import BottonmMenu2 from './module/BottonmMenu2'
	import OneVideo from './module/OneVideo'
	import ExpertTalk from './module/ExpertTalk'
	import ChatFix from './module/ChatFix'
	import MulVideo from './module/MulVideo'
	import pdfShow from './module/PdfShow'
	import SelKf from './module/SelKf'
	import MyReport from './module/MyReport'
	import ProInfo1Show from './module/ProInfo1Show'
	import MoreStyleTitle from './module/MoreStyleTitle'
	import MyQrCode from './module/MyQrCode'
	import ProductCode from './module/ProductCode'
	import XhCertificate from './module/XhCertificate'
	import XhCertificate2 from './module/XhCertificate2'
	import QKPage from './module/qkPage'
	import PackingCode from './module/PackingCode'
	import SetHandle from './module/SetHandle'
	import SetParameter from './module/SetParameter'
	import SetUserInfo from './module/SetUserInfo'
	import MyDatamatrix from './module/MyDatamatrix'
	import ScanBJWL from './module/ScanBJWL'
	import BottonmMenu3 from './module/BottonmMenu3'
	import AloneTalk from './module/AloneTalk'
	import BoxCode from './module/BoxCode'
	import BoxCode2 from './module/BoxCode2'
	import BottleState from './module/BottleState'
	import EdgeGateway from './module/EdgeGateway'
	import Equipment from './/module/Equipment'
	import DeviceNameplate from './module/DeviceNameplate'
	import ExtensionPage from './module/ExtensionPage'

	import RealSta from './module/RealSta'
	import ZxCountDown from './module/ZxCountDown'
	import SwiperVideo from './module/SwiperVideo'
	import ZXKF from './module/ZXKF'
	import productZone from './module/ProductZone'
	import changeZone from './module/ChangeZone'
	import read from './module/Read'
	import wisdom from './module/Wisdom'
	import ScanInfo from './module/ScanInfo'
	import Telbtn from './module/Telbtn'
	import BLDWine from './module/BLDWine'
	import Quality from './module/Quality'
	import OutLink from './module/OutLink'
	import Facility from './module/Facility'
	import ShoppingBtn from './module/ShoppingBtn'

	// 页面编辑
	import ScanForOne from './editpage/ScanForOne'
	import ScanForTwo from './editpage/ScanForTwo'
	import ScanForThree from './editpage/ScanForThree'
	import ScanForFour from './editpage/ScanForFour'
	import ScanForFive from './editpage/ScanForFive'
	import ScanForSix from './editpage/ScanForSix'
	import ScanForSeven from './editpage/ScanForSeven'
	import ScanForNine from './editpage/ScanForNine'
	import ScanForTen from './editpage/ScanForTen'
	import PageSet from './editpage/PageSet'
	import SwpieEdit from './editpage/SwpieEdit'
	import ImageItemEdit from './editpage/ImageItemEdit'
	import TextEdit from './editpage/TextEdit'
	import AssistEdit from './editpage/AssistKbEdit'
	import SearchInputEdit from './editpage/SearchInputEdit'
	import MyImgTextListEdit from './editpage/MyImgTextListEdit'
	import MyImgText2Edit from './editpage/MyImgText2Edit'
	import MyImgText3Edit from './editpage/MyImgText3Edit'
	import TitleImgEdit from './editpage/TitleImgEdit'
	import DetailBlock1Edit from './editpage/DetailBlock1Edit'
	import Title1Edit from './editpage/Title1Edit'
	import ConStyle1Edit from './editpage/ConStyle1Edit'
	import productGroupEdit from './editpage/productGroupEdit'
	import BottonmMenu1Edit from './editpage/BottonmMenu1Edit'
	import BottonmMenu2Edit from './editpage/BottonmMenu2Edit'
	import OneVideoEdit from './editpage/OneVideoEdit'
	import ExpertTalkEdit from './editpage/ExpertTalkEdit'
	import ChatFixEdit from './editpage/ChatFixEdit'
	import MulVideoEdit from './editpage/MulVideoEdit'
	import pdfShowEdit from './editpage/PdfShowEdit'
	import MyReportEdit from './editpage/MyReportEdit'
	import ProInfo1Edit from './editpage/ProInfo1Edit'
	import MoreStyleTitleEdit from './editpage/MoreStyleTitleEdit'
	import MyQrCodeEdit from './editpage/MyQrCodeEdit'
	import ProductCodeEdit from './editpage/ProductCodeEdit'
	import PackingCodeEdit from './editpage/PackingCodeEdit'
	import XhCertificateEdit from './editpage/XhCertificateEdit'
	import XhCertificateEdit2 from './editpage/XhCertificateEdit2'
	import QKPageEdit from './editpage/QKPageEdit'
	import SetHandleEdit from './editpage/SetHandleEdit'
	import SetParameterEdit from './editpage/SetParameterEdit'
	import SetUserInfoEdit from './editpage/SetUserInfoEdit'
	import MyDatamatrixEdit from './editpage/MyDatamatrixEdit'
	import ScanForBJWL from './editpage/ScanForBJWL'
	import BottonmMenu3Edit from './editpage/BottonmMenu3Edit'
	import AloneTalkEdit from './editpage/AloneTalkEdit'
	import BoxCodeEdit from './editpage/BoxCodeEdit'
	import BoxCodeEdit2 from './editpage/BoxCodeEdit2'
	import BottleStateEdit from './editpage/BottleStateEdit'
	import EdgeGatewayEdit from './editpage/EdgeGatewayEdit'
	import EquipmentEdit from './editpage/EquipmentEdit'
	import DeviceNameplateEdit from './editpage/DeviceNameplateEdit'
	import ExtensionPageEdit from './editpage/ExtensionPageEdit'

	import RealStaEdit from './editpage/RealStaEdit'
	import ZxCountDownEdit from './editpage/ZxCountDownEdit'
	import SwiperVideoEdit from './editpage/SwiperVideoEdit'
	import ZXKFEdit from './editpage/ZXKFEdit'
	import productZoneEdit from './editpage/productZoneEdit'
	import changeZoneEdit from './editpage/ChangeZoneEdit'
	import readEdit from './editpage/ReadEdit'
	import wisdomEdit from './editpage/WisdomEdit'
	import ScanInfoEdit from './editpage/ScanInfoEdit'
	import TelbtnEdit from './editpage/TelbtnEdit'
	import BLDWineEdit from './editpage/BLDWineEdit'
	import QualityEdit from './editpage/QualityEdit'
	import OutLinkEdit from './editpage/OutLinkEdit'
	import FacilityEdit from './editpage/FacilityEdit'
	import ShoppingBtnEdit from './editpage/ShoppingBtnEdit'


	export default {
		components: {
			MyText,
			MyImage,
			MySwipe,
			SwpieEdit,
			TextEdit,
			ImageItemEdit,
			UploadThumbnail,
			SelImage,
			BsSlider,
			BsColorPicker,
			QrCodeEdit,
			BsImageSel,
			draggable,
			SelIcon,
			SelProduct,
			ScanOne,
			ScanTwo,
			ScanForOne,
			ScanForTwo,
			ScanThree,
			ScanForThree,
			ScanFour,
			ScanForFour,
			ScanFive,
			ScanForFive,
			ScanSix,
			ScanForSix,
			ScanSeven,
			ScanForSeven,
			ScanNine,
			ScanForNine,
			ScanTen,
			ScanForTen,
			PageSet,
			PagesSet,
			AssistKb,
			AssistEdit,
			MySearchInput,
			SearchInputEdit,
			MyImgTextList,
			MyImgTextListEdit,
			MyImgText2,
			MyImgText2Edit,
			MyImgText3,
			MyImgText3Edit,
			TitleImg,
			TitleImgEdit,
			DetailBlock1,
			DetailBlock1Edit,
			Title1,
			Title1Edit,
			ConStyle1,
			ConStyle1Edit,
			productGroup,
			productGroupEdit,
			BottonmMenu1,
			BottonmMenu1Edit,
			BottonmMenu2,
			BottonmMenu2Edit,
			OneVideo,
			OneVideoEdit,
			SelVideo,
			ExpertTalk,
			ExpertTalkEdit,
			SelKf,
			ChatFix,
			ChatFixEdit,
			MulVideo,
			MulVideoEdit,
			pdfShow,
			pdfShowEdit,
			MyReport,
			MyReportEdit,
			ProInfo1Show,
			ProInfo1Edit,
			MoreStyleTitle,
			MoreStyleTitleEdit,
			MyQrCode,
			MyQrCodeEdit,
			ProductCode,
			ProductCodeEdit,
			XhCertificate,
			XhCertificateEdit,
			XhCertificateEdit2,
			XhCertificate2,
			QKPage,
			QKPageEdit,
			PackingCode,
			PackingCodeEdit,
			SetHandle,
			SetHandleEdit,
			SetParameter,
			SetParameter,
			SetParameterEdit,
			SetUserInfo,
			SetUserInfoEdit,
			MyDatamatrix,
			MyDatamatrixEdit,
			BottonmMenu3,
			BottonmMenu3Edit,
			ScanBJWL,
			ScanForBJWL,
			AloneTalk,
			AloneTalkEdit,
			BoxCode,
			BoxCode2,
			BoxCodeEdit,
			BoxCodeEdit2,
			BottleState,
			BottleStateEdit,
			EdgeGateway,
			EdgeGatewayEdit,
			Equipment,
			EquipmentEdit,
			DeviceNameplate,
			DeviceNameplateEdit,
			ExtensionPage,
			ExtensionPageEdit,

			productZone,
			productZoneEdit,
			read,
			readEdit,
			wisdom,
			wisdomEdit,
			changeZone,
			changeZoneEdit,
			ScanInfo,
			ScanInfoEdit,
			RealSta,
			RealStaEdit,
			ZxCountDown,
			ZxCountDownEdit,
			SwiperVideo,
			SwiperVideoEdit,
			ZXKFEdit,
			ZXKF,
			Telbtn,
			TelbtnEdit,
			BLDWine,
			BLDWineEdit,
			Quality,
			QualityEdit,
			OutLink,
			OutLinkEdit,
			Facility,
			FacilityEdit,
			ShoppingBtn,
			ShoppingBtnEdit
		},
		data() {
			return {
				pageMode: 'addTemplate',
				pageId: null,
				templateId: null,
				activeNames: ['1'],
				itemList: [],
				curSelIndex: 0,
				curSelData: {},
				hasPages: false, // 判断是否设置全局页面
				curImgIndex: 0,
				selImgConAttr: '', // 记录选择图片对应的哪个属性
				curProductIndex: 0,
				curBgFlag: false,
				pagesData: {},
				verState: 0, // 语音验证结果
				goodsIds: [], // 选择的商品id集合
				zxData: [], // 页面所有咨询数据
				// zx_cardId: null, // 指定客服联系人
				curVideoIndex: 0,
				selVideoConAttr: '', // 记录选择视频对应哪个属性
				imgType: 1, // 图片的宽高比
				imgInfo: {
					width: 1,
					height: 0
				},
				templateType: 0, // 模版类型（用户/内部页面）
				isIntegral: false, // 是否设置积分
				integral: 0,
				ThumbPath: '', // 缩略图
				remark: '',
				classId: null,
				pIdx: null, // 修改数据索引对应的父级索引
			}
		},

		watch: {
			Talk() {
				this.$nextTick(() => {
					var container = this.$el.querySelector('#talkBox')
					container.scrollTop = container.scrollHeight
				})
			}
		},
		mounted() {
			let templateId = undefined
			let pageId = undefined
			// 获取type模式
			if (this.$route.query.type) {
				this.pageMode = this.$route.query.type
			}
			// 获取模板类型（用户/内部页面）
			if (this.$route.query.templateType) {
				this.templateType = parseInt(this.$route.query.templateType)
			}
			// 编辑模版模式
			if (this.pageMode === 'editTemplate') {
				templateId = JSON.parse(this.$route.query.templateId)
				this.templateId = templateId
				if (templateId) {
					this.getTemplateData(templateId)
				}
			} else if (this.pageMode === 'addPage') {
				templateId = JSON.parse(this.$route.query.templateId)
				this.templateId = templateId
				if (templateId) {
					this.getTemplateData(templateId)
				}
			} else if (this.pageMode === 'editPage') {
				pageId = this.$route.query.pageId
				this.pageId = pageId
				if (pageId) {
					this.getPageData(pageId)
				}
			} else {
				this.init()
			}

			// if(this.$route.query.id) {
			//   pageId = JSON.parse(this.$route.query.id); // templateId
			//   templateId = JSON.parse(this.$route.query.templateId)
			// }
			// if(pageId) {
			//   this.pageMode = 'editTemplate'
			//   this.pageId = pageId
			//   this.getTemplateData(pageId)

			// }else {
			//   this.init()
			// }
		},
		methods: {
			scrool() {
				this.$nextTick(() => {
					var container = this.$el.querySelector('#talkBox')
					console.log('scrollHeight', container.scrollHeight)
					container.scrollTop = container.scrollHeight
				})
			},
			// 整个页面设置
			globalSettimg() {
				this.hasPages = true
				this.curSelIndex = 0
				var nPages = sessionStorage.getItem('pageData')
				if (nPages) {
					var newData = JSON.parse(nPages)
					console.log(newData.picList[0].url)
					this.curSelData = newData
					this.pagesData = newData
				} else {
					this.pagesData = {
						type: 'pages',
						picImg: '',
						pageName: '模版管理',
						content: '整个页面设置',
						align: 'left',
						fontSize: 16,
						weight: 'normal',
						bgColor: 'transparent',
						paddTop: 0,
						paddLeft: 0,
						textColor: '#000',
						bgImg: '',
						picList: [{
							url: '',
							width: '100'
						}],
						link: ''
					}
					this.curSelData = this.pagesData
				}
			},
			savePageBefore() {
				this.$refs.UploadThumbnail.openDialog()
			},
			getUploadThumb(data) {
				this.ThumbPath = data.path
				this.remark = data.remark
				this.classId = data.classId
				this.savePage()
			},
			async savePage(role) {
				let v = this
				if (!this.pagesData.pageName) {
					if (this.pageMode === 'editTemplate' || this.pageMode === 'addTemplate') {
						this.$message('请设置模版名称');
					} else {
						this.$message('请设置页面名称');
					}
					return
				}

				if (this.itemList) {
					var datas = JSON.stringify(this.itemList)
					sessionStorage.setItem('datas', datas)
					// let pageData = JSON.parse(sessionStorage.getItem('pageData'))
					let pageData = JSON.stringify(this.pagesData)
					sessionStorage.setItem('pageData', pageData)

					let params = {
						title: this.pagesData.pageName,
						type: this.templateType,
						content: JSON.stringify({
							pageData: this.pagesData,
							datas: this.itemList
						}),
						isIntegral: this.isIntegral,
						integral: this.integral,
						classId: this.classId
					}
					let result = null
					if (this.pageMode === 'addTemplate') {

						result = await fetchSaveTemplate(params)

					} else if (this.pageMode === 'editTemplate') {

						params.customId = v.templateId
						result = await fetchUpdateTemplate(params)

					} else if (this.pageMode === 'addPage') {
						params.modelId = v.templateId
						params.goodsIds = v.goodsIds ? v.goodsIds.toString() : ''
						// params.cardId = v.zx_cardId? v.zx_cardId: ''
						params.zxData = v.zxData
						params.coverImg = v.ThumbPath
						params.remark = v.remark
						console.log('参数', params.content)
						result = await fetchSavePage(params)
					} else if (this.pageMode === 'editPage') {
						params.id = v.pageId
						params.goodsIds = v.goodsIds ? v.goodsIds.toString() : ''
						params.zxData = v.zxData
						params.coverImg = v.ThumbPath
						params.remark = v.remark
						// params.cardId = v.zx_cardId
						result = await fetchUpdatePage(params)
					}

					if (result.code === 200) {
						if (this.pageMode === 'editPage' || this.pageMode === 'addPage') {
							this.$router.push('/createPageList')
						} else {
							this.$router.push('/customPageList')
						}
						this.$message({
							type: "success",
							message: "操作成功！",
						});
					}
				}

			},

			async getTemplateData(templateId) {
				let result = await fetchGetTemplate(templateId)
				console.log(JSON.stringify(result))
				if (result.code === 200) {
					let content = JSON.parse(result.data.content)
					this.hasPages = true
					this.itemList = content.datas
					this.pagesData = content.pageData
				}
				this.init()
			},
			async getPageData(pageId) {
				let result = await fetchPageData(pageId)
				if (result.code === 200) {
					let content = JSON.parse(result.data.content)
					this.hasPages = true
					this.pagesData = content.pageData
					this.itemList = content.datas
					this.zx_cardId = result.data.cardId
					this.goodsIds = result.data.goodsIds
					this.zxData = result.data.zxData ? result.data.zxData : []
					this.isIntegral = result.data.isIntegral
					this.integral = result.data.integral
					this.ThumbPath = result.data.coverImg
					this.remark = result.data.remark
				}
				this.init()
			},
			handleChange(val) {
				console.log(val)
			},
			delItem() {
				this.itemList.splice(this.curSelIndex, 1)

				if (this.curSelIndex === 0) {
					this.curSelIndex = 0
					this.curSelData = this.pagesData
					return
				}

				if (this.curSelIndex >= this.itemList.length) {
					this.curSelIndex = this.itemList.length - 1
				}
				this.curSelData = this.itemList[this.curSelIndex]
			},
			addSwipe() {
				this.itemList.push({
					type: 'swipe',
					picList: [{
						url: 'http://pic.idshanxi.com/qhsx/flowerThree.png'
					}, {
						url: 'http://pic.idshanxi.com/qhsx/flowerBanner.png'
					}],
					imgType: 1
				})
				this.joinChange()
			},
			selImg(type, attr, index, itype) {
				if (type === 'mulImage' || type === 'swipe' || type === 'image' || type === 'imgTextList' || type ===
					"imgText2" || type === "scanThree" || type === "scanFour" || type === "titleImg" || type === "imgText3" ||
					type === "xhCertificate2" || type === "qkPage" || type === "userInfo" || type === "report" || type ===
					"edgeGateway" || type === "edgeGateway" || type === "swiperVideo" || type === 'read' || type === 'wisdom' ||
					type === 'BLDWine' || type === 'Quality' || type === "facility"
					) { // 多图组 轮播图 单图组 图文列表 图文列表2 扫码组件、三、四、 日化证书、 玛钢质检报告
					this.curImgIndex = index ? index : 0
					if (type === 'swipe') {
						this.imgType = itype
					}
					if (type === 'BLDWine' || type === 'facility') {
						this.pIdx = itype // 父级索引
					}
				}
				this.$refs.selImage.dialogVisible = true
				this.selImgConAttr = attr
			},
			selKf() {
				this.$refs.selKf.dialogVisible = true
			},
			changeKf(data) {
				let v = this
				let len = 0
				let arrIndex = null
				v.zxData.forEach((item, index) => {
					if (item.hasOwnProperty(v.curSelData.zx_key)) {
						len += 1
						arrIndex = index
					}
				})
				if (len) {
					v.$set(v.zxData[arrIndex], v.curSelData.zx_key, data.cardId)
				} else {
					v.zxData.push({
						[v.curSelData.zx_key]: data.cardId
					})
				}
				v.curSelData.zx_name = data.name
				this.$refs.selKf.dialogVisible = false
			},
			async changeImg(url) {
				if (this.curSelData.type === 'pages') {
					this.pagesData.picList[0].url = url
					console.log(this.pagesData)
				} else if (this.curSelData.type === 'swipe') {
					var sImgs = false
					await this.imgSize(url, this.imgType)
					sImgs = await this.comimgSize(this.imgInfo, this.imgType)
					await this.imgShow(sImgs, url)
					console.log(this.pagesData)
				} else if (this.curSelData.type === 'expertTalk' || this.curSelData.type === 'datamatrix') {
					this.curSelData.bgImg = url
				} else if (this.curSelData.type === 'myQrCode' || this.curSelData.type === 'scanSeven' || this.curSelData
					.type === 'scanSix' || this.curSelData.type === 'scanFive' || this.curSelData.type === 'scanOne' || this
					.curSelData.type === 'scanTwo' || this.curSelData.type === 'scanNine' || this.curSelData.type ===
					'scanTen' || this.curSelData.type === 'scanBJWL') {
					if (this.selImgConAttr === 'scanBg') {
						this.curSelData.scanBg = url
					} else if (this.selImgConAttr === 'boxBg') {
						this.curSelData.boxBg = url
					} else if (this.selImgConAttr === 'picList') {
						this.itemList[this.curSelIndex].picList[this.curImgIndex].url = url
						this.$set(this.curSelData.picList[this.curImgIndex], 'url', url)
					}
				} else if (this.curSelData.type === 'productGroup' || this.curSelData.type === 'proInfo1') {
					this.curSelData.bgUrl = url
				} else if (this.curSelData.type === 'report') { // 玛钢质检报告
					if (this.selImgConAttr === 'bgUrl') {
						this.curSelData.bgUrl = url
					} else if (this.selImgConAttr === 'picList') {
						this.itemList[this.curSelIndex].picList[this.curImgIndex].url = url
						this.$set(this.curSelData.picList[this.curImgIndex], 'url', url)
					}
				} else if (this.curSelData.type === 'chatFix' || this.curSelData.type === 'zxCountDown') {
					this.curSelData.imgUrl = url
				} else if (this.curSelData.type === 'xhCertificate') { // 协会证书
					if (this.selImgConAttr === 'bgUrl') {
						this.curSelData.bgUrl = url
					} else if (this.selImgConAttr === 'cardImg') {
						this.curSelData.cardImg = url
					} else if (this.selImgConAttr === 'certificateImg') {
						this.curSelData.certificateImg = url
					} else if (this.selImgConAttr === 'licenseImg') {
						this.curSelData.licenseImg = url
					} else if (this.selImgConAttr === 'ApplicationFormImg') {
						this.curSelData.ApplicationFormImg = url
					}
				} else if (this.curSelData.type === 'xhCertificate2') {
					if (this.selImgConAttr === 'bgUrl') {
						this.curSelData.bgUrl = url
					} else if (this.selImgConAttr === 'model1') {
						this.itemList[this.curSelIndex].model1[this.curImgIndex].img = url
						this.$set(this.curSelData.model1[this.curImgIndex], 'img', url)
					} else if (this.selImgConAttr === 'model2') {
						this.itemList[this.curSelIndex].model2[this.curImgIndex].img = url
						this.$set(this.curSelData.model2[this.curImgIndex], 'img', url)
					}
				} else if (this.curSelData.type === 'qkPage') {
					if (this.selImgConAttr == 'url') {
						this.itemList[this.curSelIndex].contentImg[this.curImgIndex].url = url
					} else if (this.selImgConAttr == 'headImg') {
						this.itemList[this.curSelIndex].contentImg[this.curImgIndex].headImg = url
					} else if (this.selImgConAttr == 'picList') {
						this.itemList[this.curSelIndex].picList[this.curImgIndex].url = url
					} else if (this.selImgConAttr == 'reportImg') {
						this.itemList[this.curSelIndex].reportImg[this.curImgIndex].url = url
					} else if (this.selImgConAttr == 'wayImg') {
						this.itemList[this.curSelIndex].wayImg[this.curImgIndex].url = url
					}

				} else if (this.curSelData.type === 'productZone' || this.curSelData.type === 'realSta' || this.curSelData
					.type === 'zxkf') {
					if (this.selImgConAttr == 'icon') {
						this.curSelData.icon = url
					}
				} else if (this.curSelData.type === 'changeZone') {
					if (this.selImgConAttr == 'icon') {
						this.curSelData.icon = url
					} else if (this.selImgConAttr == 'step') {
						this.curSelData.step = url
					}
				} else if (this.curSelData.type === 'swiperVideo') {
					this.itemList[this.curSelIndex].videoList[this.curImgIndex].poster = url
				} else if (this.curSelData.type === 'read') {
					if (this.selImgConAttr == 'icon') {
						this.curSelData.icon = url
					} else if (this.selImgConAttr == 'photo') {
						this.curSelData.productList[this.curImgIndex].photo = url
					} else if (this.curSelData.type === 'wisdom') {
						if (this.selImgConAttr == 'backgroundImg') {
							this.curSelData.backgroundImg = url
						} else if (this.selImgConAttr == 'titleImg') {
							this.curSelData.titleImg = url
						} else if (this.selImgConAttr == 'tqImg') {
							this.curSelData.tqImg = url
						} else if (this.selImgConAttr == 'thImg') {
							this.curSelData.thImg = url
						} else if (this.selImgConAttr == 'num') {
							this.curSelData.list[this.curImgIndex].num = url
						} else if (this.selImgConAttr == 'pic') {
							this.curSelData.list[this.curImgIndex].pic = url
						} else if (this.selImgConAttr == 'bsImg') {
							this.curSelData.analysisList[this.curImgIndex].bsImg = url
						}
					}
				} else if (this.curSelData.type === 'setHandle') {
					this.curSelData.imgUrl = url
				} else if (this.curSelData.type === 'scanInfo') {
					if (this.selImgConAttr == 'icon') {
						this.curSelData.icon = url
					}
				} else if (this.curSelData.type === 'userInfo') {
					this.curSelData.url = url
				} else if (this.curSelData.type === 'bottleState') {
					if (this.selImgConAttr == 'boxBg') {
						this.curSelData.boxBg = url
					} else {
						this.curSelData[this.selImgConAttr].url = url
					}
				} else if (this.curSelData.type === 'edgeGateway') {
					if (this.selImgConAttr == 'proImg') {
						this.curSelData.proImg.url = url
					} else if (this.selImgConAttr == 'titleImg') {
						this.curSelData.list[this.curImgIndex].titleImg = url
					} else if (this.selImgConAttr == 'codeBgImg') {
						if (!this.curSelData.codeBgImg) {
							let obj = {
								url: url
							}
							this.$set(this.curSelData, 'codeBgImg', obj)
						} else {
							this.curSelData.codeBgImg.url = url
						}
					} else {
						this.curSelData[this.selImgConAttr] = url
					}
				} else if (this.curSelData.type === 'equipment') {
					if (this.selImgConAttr == 'zsImg') {
						this.curSelData.zsImg = url
					}
				} else if (this.curSelData.type === 'deviceNameplate') {
					if (this.selImgConAttr == 'reportImg') {
						this.curSelData.reportImg = url
					}
				} else if (this.curSelData.type === 'extensionPage') { // 清控扫码页
					if (this.selImgConAttr == 'logo') {
						this.curSelData.logo = url
					} else if (this.selImgConAttr == 'codeImg') {
						this.curSelData.codeImg = url
					} else if (this.selImgConAttr == 'oneImg') {
						this.curSelData.oneImg = url
					}
				} else if (this.curSelData.type === 'oneVideo') {
					this.curSelData['poster'] = url
				} else if (this.curSelData.type === 'BLDWine') { // 张裕白兰地
					if (this.selImgConAttr == 'homeImg') {
						this.curSelData.homeImg = url
					} else if (this.selImgConAttr == 'picList') { // 子页面图集
						this.curSelData.tabList[this.pIdx].picList[this.curImgIndex].img = url
					} else if (this.selImgConAttr == 'swiperList') { // 子页面底部轮播图集
						this.curSelData.tabList[this.pIdx].swiperList[this.curImgIndex].img = url
					}
				} else if (this.curSelData.type == 'Quality') { // 方圆证书
					if (this.selImgConAttr == 'picList') {
						this.$set(this.curSelData.picList[this.curImgIndex], 'url', url)
					} else if (this.selImgConAttr == 'listData') {
						this.$set(this.curSelData.listData[this.curImgIndex], 'numImg', url)
					} else if (this.selImgConAttr == 'tImg') {
						this.$set(this.curSelData, 'tImg', url)
					} else if (this.selImgConAttr == 'picList2') {
						this.$set(this.curSelData.picList2[this.curImgIndex], 'url', url)
					} else if (this.selImgConAttr == 'qyLogo') {
						this.$set(this.curSelData, 'qyLogo', url)
					} else if (this.selImgConAttr == 'qyImg') {
						this.$set(this.curSelData, 'qyImg', url)
					} else if (this.selImgConAttr == 'dImg') {
						this.$set(this.curSelData, 'dImg', url)
					}
				} else if (this.curSelData.type == 'facility') { // 洪洞模版
					if (this.selImgConAttr == 'logoImg') {
						this.$set(this.curSelData, 'logoImg', url)
					} else if (this.selImgConAttr == 'proImg') {
						this.$set(this.curSelData, 'proImg', url)
					} else if (this.selImgConAttr == 'botLogo') {
						this.$set(this.curSelData, 'botLogo', url)
					} else if (this.selImgConAttr == 'bg') {
						this.$set(this.curSelData, 'bg', url)
					} else if (this.selImgConAttr === 'imgList') {
						this.curSelData.list[this.pIdx].imgList[this.curImgIndex].url = url
					} else if (this.selImgConAttr === 'img') {
						this.curSelData.list[this.curImgIndex].img = url
					}
				} else {
					console.log(this.curSelData.picList[this.curImgIndex])
					this.itemList[this.curSelIndex].picList[this.curImgIndex].url = url
					this.$set(this.curSelData.picList[this.curImgIndex], 'url', url)
				}
				this.$refs.selImage.dialogVisible = false
			},
			imgShow(sImgs, url) {
				if (sImgs == true) {
					this.itemList[this.curSelIndex].picList[this.curImgIndex].url = url
					this.$set(this.curSelData.picList[this.curImgIndex], 'url', url)
				} else {
					this.$message({
						type: "warning",
						message: "请选择合适的图片！",
					});
				}
			},
			imgSize(url, type) {
				const image = new Image()
				image.src = url
				console.log(image.width, image.height)
				this.imgInfo.width = image.width
				this.imgInfo.height = image.height
			},
			comimgSize(image, type) {
				console.log('image', image)
				var num = 0
				var imgFlag = false
				if (image.width) {
					num = image.width / image.height
					num = Number(num.toFixed(1));
					console.log('num', num)
					if (type == 1) {
						imgFlag = true
					} else if (type == 2) {
						if (num > 1.5 && num < 1.9) {
							imgFlag = true
						} else {
							imgFlag = false
						}
					} else if (type == 3) {
						if (num > 1.0 && num < 1.5) {
							imgFlag = true
						} else {
							imgFlag = false
						}
					} else if (type == 4) {
						if (num == 1.0 || num == 1.1 || num == 1.2) {
							imgFlag = true
						} else {
							imgFlag = false
						}
					}
					return imgFlag
				}
			},

			selProduct(index) {
				this.curProductIndex = index
				this.$refs.selProduct.dialogVisible = true
			},
			changeProduct(data) {
				// let old = this.curSelData.productList[this.curProductIndex]
				// if(old.spname && old.type) {
				//   data.spname = old.spname
				//   data.type = old.type
				// }
				this.$set(this.curSelData.productList, this.curProductIndex, data)
				this.$refs.selProduct.dialogVisible = false
				if (!this.goodsIds) {
					this.goodsIds = []
				}
				this.goodsIds[this.curProductIndex] = data.goodsId
				console.log('商品id集合', this.goodsIds)
			},

			changeIndex(index, event) {
				// 获取点击对象
				// var el = event.currentTarget
				console.log('itemList', this.itemList)
				if (this.pagesData.type) {
					var da = JSON.stringify(this.pagesData)
					sessionStorage.setItem('pageData', da)
				}
				this.curSelIndex = index
				this.curSelData = this.itemList[index]
				this.showBorder(index)
			},
			addImg() {
				this.itemList.push({
					type: 'image',
					picList: [{
						url: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
						width: '100%'
					}],
					paddTop: 0,
					paddLeft: 0,
					align: 'center',
				})
				this.joinChange()
			},
			addMulImg() {
				this.itemList.push({
					type: 'mulImage',
					paddTop: 0,
					paddLeft: 0,
					align: 'left',
					picList: [{
							url: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
							width: '50%'
						},
						{
							url: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
							width: '50%'
						}
					]
				})
				this.joinChange()
			},
			// 搜索框
			addSearchInput() {
				this.itemList.push({
					type: 'searchInput',
					bgColor: '#eee',
					iconColor: '#ccc',
					placeholder: '请输入关键字进行搜索',
					paddTop: 5,
					paddLeft: 5,
					textAlign: 'left',
					borderRadius: '0',
					inputBgColor: '#fff',
					textColor: '#ccc',
				})
				this.joinChange()
			},
			// 商品组
			addProductGroup() {
				this.itemList.push({
					type: 'productGroup',
					title: '这是标题',
					titleVisible: true,
					subTitle: '这是小标题',
					subTitleVisible: true,
					listStyle: 2, // 1 单列模式 2 双列模式 3列表模式
					bgColor: '#fff',
					bgUrl: '',
					goodsNameVisible: true,
					goodsNameColor: '#000',
					priceVisible: true,
					priceColor: '#000',
					buyButtonStyle: 1,
					buyButtonColor: '#000',
					topIconStyleType: 1,
					topIconStyle: 1,
					topIconTxt: '推荐',
					productList: [{
							goodsName: '商品名称1',
							price: '',
							goodPhoto: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/goods-2.jpeg',
						},
						{
							goodsName: '商品名称2',
							price: '',
							goodPhoto: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/goods-2.jpeg',
						},
					]
				})
				this.joinChange()
			},
			myQrCode() {
				this.itemList.push({
					type: 'myQrCode',
					btnBgColor: '#ebc59e',
					scanBg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/023d5a4cf41591f2eb723671d2e157cc.png',
					bgColor: '#ba332e',
					codePosition: 'right',
					textSize: 18,
					paddTop: 0,
					paddLeft: 0,
					align: 'center',
					scanBgHeight: 100,
					scanBgwidth: 120,
					scanPaddTop: 40,
					scanPaddRight: 40,
					bgImg: '',
					content: '防伪二维码',
					boxBg: '',
					kjStyle: 1,
					picList: [{
						url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bo3.png',
						width: '100'
					}], // 弹窗控件图片列表
					link: '',
					showVerCode: 2,
					rightT: '防伪验证',
					rightTStyle: {
						marginTop: 0,
						fontSize: 16,
						align: 'center',
						url: 'https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/knf/knfLqfl.png'
					}
				})
				this.joinChange()
			},
			// 图文列表
			addImgTextList() {
				this.itemList.push({
					type: 'imgTextList',
					wrapTit: '这是标题',
					wrapTitColor: '#000',
					wrapEnTit: '这是副标题',
					wrapEnTitColor: '#666',
					bgColor: '#fff',
					paddTop: 10,
					paddLeft: 10,
					picList: [{
							url: '/api/base/page/ef70a5c7ba5bbb7017739ab3b8cec439.jpg',
							width: '30%', // 图片宽度
							link: '',
							subTitle: '',
							title: '这是标题',
							content: '这是内容',

							imgAlign: 'row',
							imgRadius: 0,
							conWrapAlign: 'center',

							titleAlign: 'left',
							titleColor: '#000',
							titPaddTop: 0, // 标题上下距离
							titleSize: 16,

							subTitColor: '#313131',
							subTitleSize: 12,

							contentAlign: 'left',
							contentColor: '#666',
							contentSize: 12,

							isShowLine: false,
							lineColor: '#000',

						},
						{
							url: '/api/base/page/afe24a2154a0d5b72c83fd92ee56f100.jpg',
							width: '30%', // 图片宽度
							link: '',
							subTitle: '',
							title: '这是标题',
							content: '这是内容',

							imgAlign: 'row',
							imgRadius: 0,
							conWrapAlign: 'center',

							titleAlign: 'left',
							titleColor: '#000',
							titPaddTop: 0, // 标题上下距离
							titleSize: 16,

							subTitColor: '#313131',
							subTitleSize: 12,

							contentAlign: 'left',
							contentColor: '#666',
							contentSize: 12,

							isShowLine: false,
							lineColor: '#000',
						},
						{
							url: '/api/base/page/ef70a5c7ba5bbb7017739ab3b8cec439.jpg',
							width: '30%', // 图片宽度
							link: '',
							subTitle: '这是小标题',
							title: '这是标题',
							content: '这是内容',

							imgAlign: 'row',
							imgRadius: 0,
							conWrapAlign: 'center',

							titleAlign: 'left',
							titleColor: '#000',
							titPaddTop: 0, // 标题上下距离
							titleSize: 16,

							subTitColor: '#313131',
							subTitleSize: 12,

							contentAlign: 'left',
							contentColor: '#666',
							contentSize: 12,

							isShowLine: false,
							lineColor: '#000',
						},
						{
							url: '/api/base/page/9a5abeb3bbf1375dae88854aa3c2d9bb.jpg',
							width: '30%', // 图片宽度
							link: '',
							subTitle: '这是小标题',
							title: '这是标题',
							content: '这是内容',

							imgAlign: 'row',
							imgRadius: 0,
							conWrapAlign: 'center',

							titleAlign: 'left',
							titleColor: '#000',
							titPaddTop: 0, // 标题上下距离
							titleSize: 16,

							subTitColor: '#313131',
							subTitleSize: 12,

							contentAlign: 'left',
							contentColor: '#666',
							contentSize: 12,

							isShowLine: false,
							lineColor: '#000',
						},
					]
					// bgImg: [{ url: 'http://pic.idshanxi.com/qhsx/flowerBanner.png', width: '100', link: '' }],
				})
				this.joinChange()
			},
			//清控组件
			addQK() {
				this.itemList.push({
					type: 'qkPage',
					bgColor: '#fff',
					paddTB: 5,
					paddLR: 10,
					align: 'left',
					bgImg: '',
					link: '',
					title: '日用化学品分技术委员会委员登记表',
					titleSize: 16,
					bgColor: '#e3e3e3',
					bottomWords: '国家工业互联网标识解析综合型二级节点(86.122)授权',
					bottomWordsColor: '#971f28',
					wordsColor: '#fae778',
					wordsize: 16,
					text: '中国日用化工协会团标管理办法',
					textBg: '#ededf0',
					textColor: '#000',
					textsize: 16,
					wayImg: [{
						url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/a3120a63c0e951bf07cea8442f64f090.png',
						width: '100%', // 图片宽度
					}],
					picList: [{
						url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/b8fd44ae4a3a1bcf5bef7fb12514c281.jpg',
						width: '100%', // 图片宽度
						link: ''
					}],
					reportImg: [{
						url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/a3120a63c0e951bf07cea8442f64f090.png',
						width: '100%',
					}],
					contentImg: [{
						url: 'https://86122m.com/attachment/images/1/merch/10/pYmm4FiDdyG6mypIodIjiidGp57dYr.png',
						width: '100%', // 图片宽度
						name: '徐茹',
						headImg: 'https://86122m.com/attachment/images/1/merch/10/GQtJDw049DTwm4TATAIK0DATtTHhKa.png',
						marginTop: 0,
						marginLeft: 0,
						date: '2022年8月15日',
						dateHeight: 20,
						dateSize: 8,
						number: 'CDCIA/F20220404-XF',
						numberHeight: 20,
						content: '兹聘请徐茹同志为中国日用化工协会团体标准化技术委员会日用化学品分技术委员会委员',
						contentSize: 8,
						height: '100%',
						numberSize: 8
					}]
				})
				this.joinChange()
			},
			// 图文列表2
			addImgText2() {
				this.itemList.push({
					type: 'imgText2',
					bgColor: '#fff',
					paddTop: 10,
					paddLeft: 10,
					isShowLine: true,
					lineColor: '#3A3E3A',
					lineWidth: 56,
					picList: [{
							url: '/api/base/page/8bc7df0efbf14f57fc605afc54f0cade.png',
							width: '100%', // 图片宽度
							link: '',

							title: '这是标题',
							titleColor: '#000',
							titleSize: 18,

							subTitle: '',
							subTitleColor: '#1D211D',
							subTitleSize: 14,

						},
						{
							url: '/api/base/page/79c763edc24ee5e6710efb05d54ac869.png',
							width: '100%', // 图片宽度
							link: '',

							title: '这是标题',
							titleColor: '#000',
							titleSize: 18,

							subTitle: '',
							subTitleColor: '#1D211D',
							subTitleSize: 14,

						},
					]
				})
				this.joinChange()
			},
			addImgText3() {
				this.itemList.push({
					type: 'imgText3',
					wrapTit: '这是标题',
					wrapTitColor: '#000',
					wrapEnTit: '这是副标题',
					wrapEnTitColor: '#666',
					bgColor: '#fff',
					paddTop: 16,
					paddLeft: 16,
					picList: [{
							url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/ec6368a65159228c41f8c73fc09ca20b.jpeg',
							width: '80%', // 图片宽度
							link: '',
							subTitle: '',
							title: '这是标题1',
							content: '这是内容',

							imgAlign: 'row',
							imgRadius: 0,
							conWrapAlign: 'center',

							titleAlign: 'left',
							titleColor: '#000',
							titPaddTop: 0, // 标题上下距离
							titleSize: 16,

							subTitColor: '#313131',
							subTitleSize: 12,

							contentAlign: 'left',
							contentColor: '#666',
							contentSize: 12,

							isShowLine: false,
							lineColor: '#000',

						},
						{
							url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/be70fa983952401ae4e0a314c90cc180.jpeg',
							width: '80%', // 图片宽度
							link: '',
							subTitle: '',
							title: '这是标题2',
							content: '这是内容',

							imgAlign: 'row',
							imgRadius: 0,
							conWrapAlign: 'center',

							titleAlign: 'left',
							titleColor: '#000',
							titPaddTop: 0, // 标题上下距离
							titleSize: 16,

							subTitColor: '#313131',
							subTitleSize: 12,

							contentAlign: 'left',
							contentColor: '#666',
							contentSize: 12,

							isShowLine: false,
							lineColor: '#000',
						}
					]
					// bgImg: [{ url: 'http://pic.idshanxi.com/qhsx/flowerBanner.png', width: '100', link: '' }],
				})
				this.joinChange()
			},
			addTitleImg() {
				this.itemList.push({
					type: 'titleImg',
					bgColor: '#fff',
					paddTop: 10,
					paddLeft: 10,
					isShowLine: true,
					lineColor: '#3A3E3A',
					lineWidth: 56,
					align: 'left',
					bgImg: '',
					link: '',
					content: [{
						text: ''
					}],

					title: '这是标题1',
					titleColor: '#000',
					titleSize: 18,
					lineHeight: 20,

					subTitle: '这是小标题',
					subTitleColor: '#1D211D',
					subTitleSize: 14,
					lineHeight: 20,
					picList: [{
							url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/ec6368a65159228c41f8c73fc09ca20b.jpeg',
							width: '100%', // 图片宽度
							marginTop: 10,
							marginLeft: 0,
							marginRight: 10,
							marginBottom: 10
						},
						{
							url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/custom/be70fa983952401ae4e0a314c90cc180.jpeg',
							width: '100%', // 图片宽度
							marginTop: 10,
							marginLeft: 0,
							marginRight: 10,
							marginBottom: 10
						},
					]
				})
				this.joinChange()
			},
			// 详情介绍
			addDetail1() {
				this.itemList.push({
					type: 'detailBlock1',

					bgColor: '#F7F7F7',
					conBgColor: '#fff',
					paddLeft: 10,
					paddTop: 10,
					isShowLine: true,
					lineType: 1,

					title: '关于神农晋茶',
					titleColor: '#000',
					titleSize: 18,

					enTitle: 'About Shennong Jincha',
					enTitleColor: '#000',
					enTitleSize: 12,

					contentColor: '#000',
					contentSize: 12,
					contents: [{
							content: '请输入内容'
						},
						{
							content: '请输入内容'
						}
					]

				})
				this.joinChange()
			},
			// 标题一行
			addTit1() {
				this.itemList.push({
					type: 'tit1',
					marginTop: 0,
					marginLeft: 10,
					paddTop: 10,
					paddLeft: 10,
					content: '连翘叶手工皂',
					align: 'left',
					fontSize: 18,
					fontWeight: 'bold',
					textColor: '#1D211D',

					isShowLine: true,
					lineColor: '#1D211D',
				})
				this.joinChange()
			},
			addMoreStyleTitle() {
				this.itemList.push({
					type: 'moreStyleTitle',
					marginTop: 0,
					marginLeft: 10,
					paddTop: 30,
					paddLeft: 10,
					style: '1',
					content: '两大产品分类',
					title: '',
					align: 'left',
					fontSize: 30,
					titleSize: 12,
					fontWeight: 'bold',
					textColor: '#1D211D',

					isShowLine: true,
					lineColor: '#1D211D',
				})
				this.joinChange()
			},
			// 内容一格
			addCon1() {
				this.itemList.push({
					type: 'con1',
					isShowLine: true,
					list: [{
						label: '产品口感：',
						content: '清香型',
					}]
				})
				this.joinChange()
			},
			// 辅助空白
			addAssist() {
				this.itemList.push({
					type: 'assist',
					eleHeight: 50,
					bgColor: '#fff'
				})
				this.joinChange()
			},
			// 扫码页面一
			scanOne() {
				this.itemList.push({
					type: 'scanOne',
					picImg: '',
					content: 'renxing哈哈',
					align: 'left',
					fontSize: 16,
					weight: 'normal',
					quStatus: '1',
					bgColor: 'transparent',
					paddTop: 0,
					paddLeft: 0,
					textColor: '#000',
					bgImg: '',
					scanBg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/lan/lanCodeBacks.jpg',
					boxBg: '',
					link: 'http://pic.idshanxi.com/qhsx/flowerBanner.png'
				})
				this.joinChange()
			},
			// 扫码页面二 [神农药茶]
			scanTwo() {
				this.itemList.push({
					type: 'scanTwo',
					paddTop: 20,
					paddLeft: 10,
					bgColor: '#FACB47',
					titleAlign: 'center',

					title: '防伪二维码',
					titleSize: 18,
					titleColor: '#000',

					subTitle: 'Anti-counterfeiting QR Code',
					subTitleSize: 12,
					subTitleColor: '#000',
					scanBg: '',
					boxBg: '',
				})
				this.joinChange()
			},
			// 扫码组件四 （语音）
			scanFour() {
				this.itemList.push({
					type: 'scanFour',
					scanStyle: 2,
					btnBgColor: '#5092FF',
					bgColor: '#fff',
					paddTop: 0,
					paddLeft: 0,
					bgImg: '',
					picList: [{
						url: 'https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png',
						width: '100'
					}],
					link: ''
				})
				this.joinChange()
			},
			// 扫码组件五
			scanFive() {
				this.itemList.push({
					type: 'scanFive',
					btnBgColor: '#5092FF',
					scanBg: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bq2%402x.png',
					bgColor: '#fff',
					paddTop: 0,
					paddLeft: 0,
					bgImg: '',

					titleSize: 20,
					titleColor: '#000000',
					SubheadingSize: 19,
					SubheadingColor: '#000000',
					SubtitleSize: 14,
					SubtitleColor: '#000000',
					scanSize: 80,
					scanTop: 90,
					scanLeft: 0,
					SubheadingSize: 19,
					SubheadingColor: '#000000',
					SubtitleSize: 14,
					SubtitleColor: '#000000',
					myBoxFize: 12,
					myBoxColor: '#211F1F',
					codeTitleFize: 14,
					codeTitleColor: '#3E362C',
					codeContentFize: 14,
					codeContentColor: '#242326',

					msgBox3Fize: 12,
					msgBox3Color: '#B49B51',
					msgBox4Fize: 12,
					msgBox4Color: '#3E362C',
					msgBox5Fize: 12,
					msgBox5Color: '#f00',
					msgBox6Fize: 12,
					msgBox6Color: '#f00',
					msgBox7Fize: 12,
					msgBox7Color: '#B49B51',


					boxBg: 'https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png',
					link: '',
					jumpStyle: 1,
					kjStyle: 1,
					picList: [{
						url: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/bo3.png',
						width: '100'
					}], // 弹窗控件图片列表
				})
				this.joinChange()
			},
			// 视频
			addVideo() {
				this.itemList.push({
					type: 'oneVideo',
					src: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/bolaiya_video.mp4',
					videoHeight: null,
					videoWidth: null,
				})
				this.joinChange()
			},
			selVideo(type, attr, index) {
				if (type === 'mulVideo') { // 多视频
					this.curVideoIndex = index ? index : 0
				}
				this.selVideoConAttr = attr
				this.$refs.selVideo.dialogVisible = true
			},
			getVideo(VideoSource) {
				if (this.curSelData.type === 'mulVideo') { // 多视频
					if (this.selVideoConAttr === 'videoList') {
						this.$set(this.curSelData.videoList[this.curVideoIndex], 'url', VideoSource)
					}
				} else {
					this.curSelData.src = VideoSource
				}
			},
			// 商城+咨询
			bottonmMenu1() {
				this.itemList.push({
					type: 'bottonmMenu1',
					lfBtnText: '商城',
					lfBtnWidth: 50,
					lfBtnBgColor: '#fff',
					lfTextColor: '#000',
					rtBtnText: '咨询',
					rtBtnWidth: 50,
					rtBtnBgColor: '#c22131',
					rtTextColor: '#fff',

					zx_key: '',
					zx_name: '',
					// zx_cardId: null,
				})
				this.joinChange()
			},
			// 专家咨询按钮
			expertTalk() {
				this.itemList.push({
					type: 'expertTalk',
					height: 100,
					left: 50,
					unit: 1,
					bgImg: '',
					btnStyle: 1,
					zx_key: '',
					zx_name: '',
				})
				this.joinChange()
			},
			// 扫码组件三 （语音）
			scanThree() {
				this.itemList.push({
					type: 'scanThree',
					bgColor: '#fff',
					paddTop: 0,
					paddLeft: 0,
					bgImg: '',
					picList: [{
						url: 'https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png',
						width: '100'
					}],
					link: ''
				})
				this.joinChange()

			},
			// 聊一聊
			chatFix() {
				this.itemList.push({
					type: 'chatFix',
					imgUrl: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic65.nipic.com%2Ffile%2F20150415%2F7828787_111539068000_2.jpg&refer=http%3A%2F%2Fpic65.nipic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1650359465&t=ec7ddee7e8c37272f337506059d861c1',
					text: '聊一聊',
					bottom: 200,
					btnBgColor: '#20a0ff',
					zx_key: '',
					zx_name: '',
					// zx_cardId: null,
				})
				this.joinChange()
			},
			addQrcode() {
				this.itemList.push({
					type: 'qrcode',
					text: '123',
					size: 200,
					top: 0,
					bottom: 0,
					left: 0,
					right: 0
				})
			},
			showBorder(index) {
				var sList = document.querySelectorAll('.showItem')
				var len = this.itemList.length - 1
				sList.forEach((item, index) => {
					item.className = 'showItem'
				})
				console.log('index', index)
				if (index >= 0) {
					setTimeout(() => {
						// console.log(sList[len])
						sList[index].className = 'showItem item-sel'
					}, 200)
				} else {
					console.log('有index', index)
					setTimeout(() => {
						// console.log(sList[len])
						sList[len].className = 'showItem item-sel'
						console.log('有len', len)
					}, 200)
				}
			},
			setHandle() {
				this.itemList.push({
					type: 'setHandle',
					paddTB: 5,
					paddLR: 10,
					handle: '86.122.10110/lqc202101271',
					handleSize: 20,
					url: 'https://idshanxi.com/img/logoForImg.518b447e.png',
					content: '国家工业互联网标识解析（合肥）',
					contentSize: 13,

				})
				this.joinChange()
			},
			setParameter() {
				this.itemList.push({
					type: 'setParameter',
					nutrients: {
						flag: false,
						table: [{
							name: '项目',
							weight: '每100克(g)',
							proportion: 'NRV%'
						}]
					},
					btn: true,
					list: [{
							label: '产品名称',
							content: '连翘组合茶',
							text: '',
							flag: false
						},
						{
							label: '原料',
							content: '连翘嫩叶',
							text: '',
							flag: false
						}, {
							label: '执行标准',
							content: 'GB/T14456.3-2016',
							text: '',
							flag: false
						}, {
							label: '生产许可证编号',
							content: 'SXZF11414042400016',
							text: '',
							flag: false
						}, {
							label: '保质期',
							content: '24个月',
							text: '',
							flag: false
						}, {
							label: '净含量',
							content: ' >500g ',
							text: '',
							flag: false
						}, {
							label: '食用方法',
							content: '',
							text: ' 茶具用沸腾的开水冲洗加温后，放入适量之茶叶再以沸水冲泡即可，一次的茶叶可依个人喜好连续冲泡数次，使用陶土制之茶具泡茶其风味更佳。',
							flag: false
						}, {
							label: '储存条件',
							content: '密封、防潮、防晒、冷藏 ',
							text: '',
							flag: false
						}, {
							label: '公司名',
							content: '山西神农氏药茶农业科技有限公司',
							text: '',
							flag: false
						}, {
							label: '委托方',
							content: '山西盘秀山农业科技有限公司',
							text: '',
							flag: false
						}, {
							label: '地址',
							content: '山西综改示范区太原唐槐园区康寿街11号孵化基地4号楼2409室',
							text: '',
							flag: false
						}
					]
				})
				this.joinChange()
			},
			shoppingBtn() {
				this.itemList.push({
					type: 'shoppingBtn',
					paddTop: 10,
					text: '商城',
					icon: 'https://mzmpic.oss-cn-beijing.aliyuncs.com/yimai_photos/customPage/template/221646961587_.pic.jpg',
					bgColor: 'rgba(8, 185, 239, 1)',
					textColor: '#fff',
					textSize: 14
				})
				this.joinChange()
			},
			setUserInfo() {
				this.itemList.push({
					type: 'userInfo',
					handle: '86.122.2/A702BZX201',
					erwm: '',
					url: 'https://idshanxi.com/cms/image/ryscygzy.png',
					info: [{
						lable: '姓名',
						text: '张毅'
					}, {
						lable: '工号',
						text: 'A702BZX201'
					}, {
						lable: '工位',
						text: 'A区07二楼包装线'
					}, {
						lable: '状态',
						text: '工作中'
					}, {
						lable: '体温',
						text: '36.3℃'
					}, {
						lable: '安全培训',
						text: '完成'
					}]
				})
				this.joinChange()
			},
			joinChange() {
				this.scrool()
				setTimeout(() => {
					this.showBorder()
				}, 200)
				var len = this.itemList.length - 1
				this.changeIndex(len)
			},
			init() {
				if (this.itemList.length !== 0) {
					this.curSelData = this.itemList[this.curSelIndex]
				} else {
					this.globalSettimg()
				}
			},
			delPic(index) {
				this.curSelData.picList.splice(index, 1)
			},
			// 扫码组件三，语音验证
			veridicalCode(data) {
				// todo ajax
				this.verState = 1
			}
		}
	}
</script>
<style scoped lang="scss">
	.wrapper {
		min-height: 660px;
		height: auto !important;
	}

	.editNowBox {
		width: 40%;
		margin-left: 5%;

		>div {
			padding: 20px;
			background-color: #fff;
			border-radius: 15px;
			box-shadow: 0 0 5px #ccc;
			max-height: 660px;
			overflow: auto;
		}

		.delComBtn {
			// margin: 0 auto;
		}
	}

	.saveBox {
		width: 30%;
		height: 60px;
		background: #fff;
		box-shadow: 0 0 5px #e2e2e2;
		border-radius: 10px;
		position: fixed;
		right: 15%;
		bottom: 10px;
		z-index: 10;
	}

	.saveTitle {
		width: 100px;
		height: 30px;
		line-height: 30px;
		background: #20a0ff;
		color: #fff;
		font-size: 14px;
		text-align: center;
		border-radius: 10px;
		margin: 15px 15px 0;
		cursor: pointer;
	}

	.left-con {
		width: 90%;
		height: 100%;
		border: 1px solid black;
		box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
	}

	/* /deep/ .el-card__header {
  padding: 8px;
} */

	.item-sel {
		border: 3px dashed red;
	}

	/*.btns{*/
	/*  position: absolute;*/

	/*}*/

	.diy_phone {
		border: 4px solid black;
		background-color: black;
		border-radius: 16px;
	}

	.head_phone {
		height: 44px;
		position: relative;
		background-color: black;
	}

	.diy_phone .head_phone:before {
		content: "";
		position: absolute;
		top: 15px;
		left: 149px;
		height: 8px;
		width: 8px;
		background: #ddd;
		border-radius: 8px;
	}

	.diy_phone .head_phone:after {
		content: "";
		position: absolute;
		top: 15px;
		left: 168px;
		height: 8px;
		width: 50px;
		background: #ddd;
		border-radius: 8px;
	}

	.content-phone {
		height: 660px;
		background-color: white;
		overflow-y: auto;
		/*padding-top: 41px;*/
		position: relative;
		z-index: 9;
	}

	::-webkit-scrollbar {
		width: 0;
	}

	.diy_phone .phone-foot {
		height: 24px;
		width: 60px;
		margin: 15px auto 5px;
		border: 1px solid #ddd;
		border-radius: 5px;
		background-color: black;
	}

	.headBox {
		width: 100%;
		height: 40px;
		background-color: #3e4144;
		color: #fff;
		position: relative;
		left: 0;
		top: 0;
		line-height: 40px;
	}

	.titHead {
		font-size: 16px;
		text-align: center;
	}

	.backBox {
		width: auto;
		height: 40px;
		line-height: 40px;
		position: absolute;
		left: 15px;
		top: 0;
		font-size: 14px;
		color: #ccc;
	}

	.iconImg {
		width: 18px;
		height: auto;
		margin: 1px -2px 0 0;
		vertical-align: middle;
	}

	.buttonsWrap {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;

		.el-button {
			margin: 5px;
			min-width: 95px;
		}
	}

	.smallTip {
		color: #333;
		padding-left: 10px;
	}

	.myIntegralSeting {
		margin-bottom: 10px;
	}
</style>