<template>
  <div class="rBox">
    <!-- <el-col>
      <el-input v-model="curSelData.height" placeholder="请输入高度" style="margin: 10px 0">
        <template slot="append">高度</template>
      </el-input>
    </el-col> -->
    <el-col style="margin-bottom: 10px">
      <el-divider content-position="left">图片比例（宽高比）</el-divider>
      <el-radio-group v-model="curSelData.imgType" @change="changeRadio">
        <el-radio :label="1">无</el-radio>
        <el-radio :label="2">16：9</el-radio>
        <el-radio :label="3">4：3</el-radio>
        <el-radio :label="4">1：1</el-radio>
      </el-radio-group>
    </el-col>
    <el-card v-for="(item,index) in curSelData.picList" :key="item.url+index+'edit'" class="box-card" style="margin-top: 10px">
      <div slot="header" class="clearfix">
        <el-button style="float: right; padding: 3px 0" type="text" @click="delPic(index)">删除</el-button>
      </div>
      <el-row>
        <el-col :span="6">
          <el-image :src="item.url" style="display: block;height: 74px;width: 148px;max-width: 90%">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline" />
            </div>
          </el-image>
        </el-col>
        <el-col :span="18">
          <el-input v-model="item.url" placeholder="请输入图片地址">
            <template slot="append"><span style="cursor: pointer" @click="selImg('swipe','picList',index,curSelData.imgType)">选择图片</span></template>
          </el-input>
          <el-input v-model="item.link" placeholder="请输入内容" style="margin-top: 10px">
            <template slot="append">选择链接</template>
          </el-input>
        </el-col>
      </el-row>
    </el-card>
    <el-button style="margin:20px auto;width: 80%;display: block" @click="addSwipeItem">添加</el-button>
  </div>
</template>
<script>
export default {
  props: {
    curSelData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      radioType:1,
      imgFlag: false,
      imgInfo: {
        width: 1,
        height: 1
      }
    }
  },
  methods: {
    delPic(index) {
      this.curSelData.picList.splice(index, 1)
    },
    changeRadio(val) {
      this.imgFlag = true
      var that = this
      if (this.curSelData.picList.length>0) {
        this.curSelData.picList.forEach((item,index) => {
          that.imgSize(item.url,val,index)
        })
      }
    },
    imgSize(url,type,index) {
      const image = new Image()
      image.src = url
      console.log(image.width, image.height)
      this.imgInfo.width = image.width
      this.imgInfo.height = image.height
      this.comimgSize(this.imgInfo,type,index)
    },
    comimgSize(image, type,index) {
      console.log('image',image)
      var num = 0
      var iNum= index+ 1
      var tit = '您的第' + iNum + '张图片不符合规定尺寸！'
      if (image.width) {
        num = image.width/image.height
        num = Number(num.toFixed(1));
        console.log('num',num)
        if (type == 1) {

        } else if(type ==2) {
          if ( num > 1.5 && num < 1.9 ) {

          } else {
            if (this.imgFlag) {
              this.$message({
                type: "warning",
                message: tit,
              });
            }

          }
        } else if(type ==3) {
          if (num > 1.0 && num < 1.5) {

          } else {
            if (this.imgFlag) {
              this.$message({
                type: "warning",
                message: tit,
              });
            }
          }
        } else if(type ==4) {
          if (num == 1.0 || num == 1.1) {

          } else {
            if (this.imgFlag) {
              this.$message({
                type: "warning",
                message: tit,
              });
            }
          }
        }
        this.imgFlag = false
        /*if (imgFlag) {
          setTimeout(()=> {
            this.imgFlag = false
          },3000)
        }*/
      }
    },
    selImg(type, attr,index,itype){
      // this.$emit('selImg',type, attr,index)
      this.$emit('selImg',type, attr,index,itype)
    },
    addSwipeItem() {
      if (this.curSelData.picList.length > 3) {
        alert('最多可添加4张轮播')
        return
      }
      this.curSelData.picList.push({ url: 'https://img2.baidu.com/it/u=2740972157,329532465&fm=26&fmt=auto&gp=0.jpg' })
    }
  }
}
</script>
