<template>
    <div :style="{ 'borderBottom': data.bottomshow ? '5px solid #F0F2F7' : '' }">
        <div class="top">
            <img class="icon" :src="data.icon" alt="">
            <div class="title">{{ data.title }}</div>
        </div>
        <div class="scanInfo">
            <div class="prefixEwmImg">
                <vue-qr :size="data.scanSize" :margin="0" :auto-color="true" :dot-scale="1" :text="codeUrl" />
            </div>
            <div class="rightC">
                <div class="rctop">
                    <img class="codeIcon" :src="data.codeIcon" alt="" srcset="">
                    <div>
                        <div class="hname">工业互联网标识码：</div>
                        <div class="hcontent">{{handle ? handle : '86.122.2/lan202102261'}}</div>
                    </div>
                </div>
                <div class="lssue">{{ data.qfdw }}</div>
            </div>
        </div>
        <div class="footer">
            <div><span class="tname">首次扫码时间：</span><span class="tcontent">2023年03月16日11时11分</span></div>
            <div><span class="tname">首次扫码地点：</span><span class="tcontent">上海市</span></div>
        </div>
    </div>
</template>

<script>

import VueQr from 'vue-qr'
// import $ from 'jquery'
export default {
    name: 'ScanFive',
    components: {
        VueQr
    },
    props: {
        data: {
            type: Object
        },

        verState: Number,
        handleLink: String,
        handle: String,
        query: {
            type: String
        },
        h: {
            String
        },
        codeData: {
            type: Object
        }
    },
    data() {
        return {
            codeUrl: '',
            veridicalCode: '', // 防伪码
            errTip: false,
            // verState: 0, // 0 未验证 1 验证成功 2 验证失败
            txmDialogVisible: false,
        }
    },
    methods: {
        setCode() {
            if (this.handleLink) {
                this.codeUrl = this.handleLink
            } else {
                this.codeUrl = 'https://fwapi.lfsnms.com/h?h=aaamz'
            }
        },
        // 点击验证
        toVeridical() {
            if (!this.veridicalCode.trim()) {
                this.errTip = true
                this.$emit('veridicalCode', this.veridicalCode)
            } else {
                this.errTip = false
                // 调接口
                this.$emit('veridicalCode', this.veridicalCode)
            }
        },
        openTxmDialog() {
            this.txmDialogVisible = true
        },
        closeTxmDialog() {
            this.txmDialogVisible = false
        }
    },
    mounted() {
        this.setCode()
    },
    computed: {
        imgStyle(picItem, item) {
            return (n, item) => {
                var wid
                var pos
                var str
                // pos = item.align
                if (n.width) {
                    wid = n.width
                } else {
                    wid = '100%'
                }
                str = 'width: ' + wid
                if (pos === 'left') {
                    console.log('pos', pos)
                    str = `width: ${wid};float: ${pos}`
                } else if (pos === 'right') {
                    console.log('pos', pos)
                    str = `width: ${wid};float: ${pos}`
                } else if (pos === 'center') {
                    console.log('pos', pos)
                    str = `width: ${wid};margin: 0 auto;`
                }
                return str
            }
        }
    }
}
</script>

<style scoped>
.ewmBoxWrap {
    width: 100%;
    height: auto;
    font-size: 16px;
    /* background: url('https://86122m.com/addons/ewei_shopv2/plugin/diypage/static/images/lan/backHe.png'); */
    background-size: cover;
    position: relative;
}

.top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
    border-bottom: 1px solid #F5F6FA;
}

.icon {
    width: 22px;
    height: 25px;
    margin-right: 10px;
}

.title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    padding: 10px 0;
}

.prefixEwmImg {
    padding: 10px;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.scanInfo {
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
    align-items: center;
}

.rightC {
    text-align: left;
    margin-left: 20px;
}

.hname {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #3B4D56;
}

.hcontent {
    font-size: 14px;
    font-family: Arial;
    font-weight: bold;
    color: #0099D6;
}

.lssue {
    margin-top: 20px;
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #808080;
}

.rctop {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.codeIcon {
    margin-right: 10px;
    width: 28px;
    height: 30px;
}

.footer {
    margin: 20px;
    padding: 10px 20px;
    text-align: left;
    background: #FAFAFC;
    border-radius: 10px;
}

.tname {
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.tcontent {
    font-size: 11px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0099D6;
}
</style>
