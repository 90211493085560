<template>
  <div>
    <el-form label-width="90px">
        <el-form-item label="大标题">
            <el-input v-model="curSelData.title" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="小标题">
            <el-input v-model="curSelData.subTitle" placeholder="请输入标题">
            </el-input>
        </el-form-item>
        <el-form-item label="logo">
            <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.logoImg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.logoImg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('facility', 'logoImg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
        </el-form-item>
        <el-form-item label="产品图片">
            <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.proImg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.proImg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('facility', 'proImg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
        </el-form-item>

      <!--核心-->
      <el-card v-for="(item,index) in curSelData.list" :key="item.tit" class="box-card">
          <div slot="header" class="delBtn" @click="delItem('list',index)">删除</div>
          <el-form-item label="标题">
              <el-input v-model="item.tit" />
          </el-form-item>
          <el-form-item label="小标题">
              <el-input v-model="item.eng" />
          </el-form-item>
          <el-form-item label="小标题">
           <el-radio v-model="item.align" label="left">居左</el-radio>
           <el-radio v-model="item.align" label="right">居右</el-radio>
          </el-form-item>

         <el-form-item label="内容形式">
           <el-radio v-model="item.type" :label="1">文字</el-radio>
           <el-radio v-model="item.type" :label="2">列表排序</el-radio>
           <el-radio v-model="item.type" :label="3">列表</el-radio>
           <el-radio v-model="item.type" :label="4">2张图片</el-radio>
           <el-radio v-model="item.type" :label="5">单张图片</el-radio>
          </el-form-item>

          <template v-if="item.type == 1">
             <el-form-item label="内容">
              <el-input type="textarea" v-model="item.content" placeholder="请输入内容">
              </el-input>
             </el-form-item>
          </template>

          <template v-if="item.type == 2">
            <el-form-item :label="`列表项${cIndex+1}`" v-for="(cItem, cIndex) in item.sortList" :key="cItem.con">
               <el-input type="textarea" v-model="cItem.con" placeholder="请输入内容">
              </el-input>
              <el-button class="addBtn" @click="delItem('sortList', cIndex, index)">删除</el-button>
            </el-form-item>

            <el-button class="addBtn" @click="addItem('sortList', index)">添加一项</el-button>
          </template>

          <template v-if="item.type == 3">
            <div v-for="(cItem, cIndex) in item.list" :key="cItem.key" class="cItem">
             <el-form-item label="名称" >
                <el-input type="textarea" v-model="cItem.key" placeholder="请输入标题">
                </el-input>
              </el-form-item>
               <el-form-item label="内容" >
                <el-input type="textarea" v-model="cItem.value" placeholder="请输入内容">
                </el-input>
              </el-form-item>
              <el-button class="addBtn" @click="delItem('clist', cIndex, index)">删除</el-button>

            </div>
            <el-button class="addBtn" @click="addItem('list', index)">添加一项</el-button>
          </template>

          <template v-if="item.type == 4">
            <el-row v-for="(cItem,cIndex) in item.imgList" :key="cItem.url">
                  <el-col :span="6">
                      <el-image :src="cItem.url"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="cItem.url" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('facility', 'imgList', cIndex, index)">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </template>

          <template v-if="item.type == 5">
              <el-row>
                  <el-col :span="6">
                      <el-image :src="item.img"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="item.img" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('facility', 'img', index)">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
          </template>

      </el-card>

      <el-button @click="addMo('list')" class="addBtn">添加模块</el-button>
       
         <el-card v-for="(cItem) in curSelData.qyList" :key="cItem.qyTit" class="box-card">
          <div slot="header" class="delBtn" @click="delItem('qyList', index)">删除</div>
            <el-form-item label="标题">
                <el-input v-model="cItem.qyTit" placeholder="请输入标题">
                </el-input>
            </el-form-item>
            <el-form-item label="小标题">
                <el-input v-model="cItem.qyEng" placeholder="请输入标题">
                </el-input>
            </el-form-item>
            <el-form-item label="企业介绍">
                <el-input type="textarea" v-model="cItem.qyText" placeholder="请输入内容">
                </el-input>
            </el-form-item>
         </el-card>

         <el-button class="addBtn" @click="addMo('qyList')">添加模块</el-button>


         <el-form-item label="底部logo">
            <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.botLogo"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.botLogo" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('facility', 'botLogo')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
        </el-form-item>
        <el-form-item label="背景图">
            <el-row>
                  <el-col :span="6">
                      <el-image :src="curSelData.bg"
                          style="display: inline-block;height: 74px;width: 148px;max-width: 90%">
                          <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline" />
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="18">
                      <el-input v-model="curSelData.bg" placeholder="请输入图片地址">
                          <template slot="append"><span style="cursor: pointer"
                                  @click="selImg('facility', 'bg')">选择图片</span></template>
                      </el-input>
                  </el-col>
              </el-row>
        </el-form-item>
        

    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    curSelData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },
  methods: {
      selImg(type, attr, index, itype) {
        this.$emit('selImg', type, attr,index, itype)
      },
      addMo(type) {
          let oneListItem = {
									tit:'功能介绍',
									eng:'Function introduction',
									align: 'left', // 标题位置
									type: 1, // 只展示文字 2展示数字 3 展示列表 4 展示图片（多图）5 展示单图
									content: '内容内容',
									sortList: [
										{
											con: ''
										},
									],
									list: [
										{
											key:'',
											value: '',
										},
									],
									imgList: [
										{
											url: '',
										},
										{
											url: '',
										},
									],
									img: '',
                                }
         
          let oneQyItem = {
              		qyTit: '',
                    qyEng: '',
                    qyText: '',
                }
          if(type === 'list') {
            this.curSelData.list.push(oneListItem)
          }
          if(type === 'qyList') {
         this.curSelData.qyList.push(oneQyItem)
          }
      },
      addItem(type,index) {
          let oneSortItem = {
            con:''
          }
          let oneItem = {
            key:'',
			value: '',
          }
          if(type === 'sortList') {
            this.curSelData.list[index].sortList.push(oneSortItem)
          }else if(type === 'list') {
            this.curSelData.list[index].list.push(oneItem)
          }
      },
      delItem(type,index,pIndex) {
          if(type === 'list') {
             this.curSelData.list.splice(index,1)
          }else if(type === 'qyList') {
             this.curSelData.qyList.splice(index,1)
          }else if(type === 'sortList') {
           this.curSelData.list[pIndex].sortList.splice(index,1)
          }else if(type === 'clist') {
           this.curSelData.list[pIndex].list.splice(index,1)
          }
      }
  }
}
</script>

<style scoped>
.delBtn{
  color: #f00;
  float: right;
  padding-bottom: 10px;
}
.addBtn{
  width: 90%;
  margin: 10px auto;
  display: block;
}
.cItem{
  border: 1px solid #efefef;
  margin: 10px 0;
}
</style>